import useStore from 'model/store';
import mqtt, { type MqttClient } from 'mqtt';
import { useEffect, useState } from 'react';

export enum Category {
  SYSTEM = 'system',
  HARDWARE = 'hardware',
  WIFI = 'wifi',
  MQTT = 'mqtt',
  OTA = 'ota',
}

export enum Severity {
  CRIT = 2,
  ERR = 3,
  WARNING = 4,
  INFO = 6,
  DEBUG = 7,
}

interface Payload {
  message: string;
  index: number;
}

export interface DeviceAlert {
  category: Category;
  payload: Payload;
  severity: Severity;
  deviceId: string;
  unixTimeS: number;
  sequence: number;
}

interface MqttMessageProps {
  beaconId: string;
  skip?: boolean;
}

export default function useMqttMessage({ beaconId, skip }: MqttMessageProps) {
  const user = useStore((state) => state.user);
  const token = useStore((state) => state.token);
  const organizationUuid = useStore(
    (state) => state.organizationSettings.organizationUuid,
  );
  const [client, setClient] = useState<MqttClient | undefined>();
  const [mqttUser] = useState(user?.email ?? '');
  const [message, setMessage] = useState<DeviceAlert | undefined>(undefined);
  const [topic, setTopic] = useState<string | undefined>(undefined);
  const [timestamp, setTimestamp] = useState<Date | undefined>(undefined);

  const broker = `wss://${import.meta.env.VITE_MQTT_BROKER}:${
    import.meta.env.VITE_MQTT_WEBSOCKET_PORT
  }/mqtt`;

  useEffect(() => {
    if (client) {
      client.on('connect', () =>
        client.subscribe(`mda2/${organizationUuid}/alert/${beaconId}`, {
          qos: 0,
        }),
      );
      client.on('error', (error) => {
        console.error(error);
      });
      client.on('message', (topic: string, message: Buffer) => {
        const jsonData = JSON.parse(
          new TextDecoder().decode(message),
        ) as DeviceAlert;
        setMessage(jsonData);
        setTopic(topic);
        setTimestamp(new Date());
      });
    }

    return () => {
      client?.end();
    };
  }, [client, beaconId, organizationUuid]);

  useEffect(() => {
    // Check if user has ACLs, otherwise he won't be able to subscribe
    // Do not subscribe if "skip" is true as Bluerange doesn't support all features
    if (!client && beaconId && user?.acls?.sub && !skip) {
      setClient(
        mqtt.connect(broker, {
          username: mqttUser,
          password: token,
          reconnectOnConnackError: true,
          clientId: `mda2-${mqttUser}-${Math.random()
            .toString(16)
            .substring(2, 8)}`,
          transformWsUrl(url, _, client) {
            // Needs to call the store like that as otherwise it will keep the old value
            client.options.password = useStore.getState().token;

            return url;
          },
        }),
      );
    }
  }, [mqttUser, beaconId, client, user?.acls?.sub, skip, token]);

  return { message, topic, timestamp };
}
