import { FormattedMessage, useIntl } from 'translations/Intl';

import Checkbox from '@/generic/components/Form/Checkbox';
import { MqttSystems, useSetSceneIdentifyMutation } from '@/graphql/types';
import SetSceneButton from '@/pages/StatusView/components/RowSubComponent/components/BluerangeMqttAction/components/SetSceneButton';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import { useState } from 'react';
import { HiOutlineLightBulb } from 'react-icons/hi2';

interface SetSceneIdentifyButtonProps {
  mqttTopic: string;
}

export default function SetSceneIdentifyButton({
  mqttTopic,
}: SetSceneIdentifyButtonProps) {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [smallIdentify, setSmallIdentify] = useState(false);
  const [, setSceneIdentifyMutation] = useSetSceneIdentifyMutation();

  return (
    <SetSceneButton
      renderIcon={() => <HiOutlineLightBulb />}
      title="Set scene identify"
      timeout
      onProceed={() =>
        setSceneIdentifyMutation(
          {
            Topics: [mqttTopic],
            MqttSystem: MqttSystems.Mda2,
            SmallIdentify: smallIdentify,
          },
          hasuraHeader(HasuraPermissions.VIEW_ADMIN),
        ).then((data) => {
          if (data.error) {
            toast(data, {
              message: {
                type: 'error',
                content: intl.formatMessage({
                  id: 'Error blinking device',
                }),
              },
            });
          }
        })
      }
    >
      <FormattedMessage id="Identify scene" />
      <Checkbox
        data-test-id="group-roles-checkboxes"
        checked={smallIdentify}
        setChecked={setSmallIdentify}
        label={intl.formatMessage({ id: 'Small identify' })}
        tooltip={intl.formatMessage({ id: 'small-identify-description' })}
      />
    </SetSceneButton>
  );
}
