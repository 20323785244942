import { FaCircleNotch } from 'react-icons/fa';
import Button from '.';
import type { ButtonProps } from './Button';

interface StyledButtonProps extends ButtonProps {
  active?: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
  ref?: React.RefObject<HTMLButtonElement>;
}

export default function StyledButton({
  ref,
  className,
  children,
  active,
  isLoading,
  ...rest
}: StyledButtonProps) {
  const colors = active
    ? 'bg-primary-600 dark:bg-primary-700 disabled:hover:bg-primary-600 dark:disabled:hover:bg-primary-700 text-white'
    : 'bg-primary-200 dark:bg-primary-400 dark:text-white hover:bg-primary-600 dark:hover:bg-primary-700 disabled:hover:bg-primary-200 dark:disabled:hover:bg-primary-400 text-primary-500 hover:text-white disabled:hover:text-primary-500 dark:disabled:hover:text-white';

  return (
    <Button
      disabled={isLoading}
      ref={ref}
      {...rest}
      className={`font-medium py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-solid border-transparent focus:outline-hidden focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-neutral-800 ${colors} ${className ?? ''}`}
    >
      {isLoading && (
        <FaCircleNotch className="animate-spin size-5 text-neutral-200" />
      )}
      {children}
    </Button>
  );
}
