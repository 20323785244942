import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import ZoomButtons from '@/generic/components/ZoomButtons';
import type OLMap from 'ol/Map';
import CanvasButton from 'pages/AdminView/BuildingView/components/FloorList/components/FloorRoomView/components/FloorRoomMap/components/CanvasButton';
import { useEffect, useState } from 'react';
import { HiOutlineArrowDownTray } from 'react-icons/hi2';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: BlobPart, defaultName?: string) => boolean;
  }
}

interface HTMLCanvasElementExtended extends HTMLCanvasElement {
  msToBlob?: (blob?: BlobPart) => BlobPart;
}

interface CanvasExportButtonProps {
  map: OLMap;
  name?: string;
  zoom: number;
}

const FORMAT = 'image/png';
const FORMAT_EXT = 'png';
export const CANVAS_EXPORT_MAP_ID = 'canvas-export-map';

const getDownloadImageName = () =>
  `${window.document.title.replace(/ /g, '_').toLowerCase()}` +
  `.${FORMAT_EXT}`;

const downloadCanvasImage = (
  canvas: HTMLCanvasElementExtended,
  name: string | undefined,
) =>
  // Use blob for large images
  new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const link = document.createElement('a');
        link.download = name || getDownloadImageName();
        link.href = URL.createObjectURL(blob);
        // append child to document for firefox to be able to download.
        document.body.appendChild(link);
        link.click();
        resolve(blob);
      }
    }, FORMAT);
  });

export default function CanvasExportButton({
  map,
  name,
  zoom,
}: CanvasExportButtonProps) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [realMapRef, setRealMapRef] = useState<
    string | HTMLElement | undefined
  >(undefined);
  const [modalMapRef, setModalMapRef] = useState<HTMLElement | null>(null);
  const createCanvasImage = (mapToExport: OLMap) =>
    new Promise<HTMLCanvasElementExtended | undefined>((resolve) => {
      mapToExport.once('rendercomplete', () => {
        // Find all layer canvases and add it to dest canvas.
        const canvases = mapToExport
          .getTargetElement()
          .getElementsByTagName('canvas');

        // Create the canvas to export with the good size.
        let destCanvas: HTMLCanvasElementExtended | undefined;
        let destContext: CanvasRenderingContext2D | null = null;

        for (let i = 0; i < canvases.length; i += 1) {
          const canvas = canvases[i];
          if (!canvas?.width || !canvas.height) {
            continue;
          }
          const clip = {
            x: 0,
            y: 0,
            w: canvas.width,
            h: canvas.height,
          };

          if (!destCanvas) {
            destCanvas = document.createElement('canvas') as HTMLCanvasElement;
            destCanvas.width = clip.w;
            destCanvas.height = clip.h;
            destContext = destCanvas.getContext('2d');
          }

          // Draw canvas to the canvas to export.
          if (destContext) {
            destContext.drawImage(
              canvas,
              clip.x,
              clip.y,
              clip.w,
              clip.h,
              0,
              0,
              destCanvas.width,
              destCanvas.height,
            );
          }
        }
        resolve(destCanvas);
      });
      mapToExport.renderSync();
    });

  const canvasExport = () =>
    createCanvasImage(map)
      .then((canvas) => {
        if (canvas) {
          downloadCanvasImage(canvas, name);
        }
      })
      .catch((err) => {
        if (err) {
          console.error(err);
        }
      });

  useEffect(() => {
    if (open) {
      if (!realMapRef) {
        setRealMapRef(map.getTarget());
      }
      if (modalMapRef) {
        // Move the map into the modal
        map.setTarget(modalMapRef);
      }
    } else {
      if (realMapRef) {
        // Set map back into the normal Card
        map.setTarget(realMapRef);
      }
    }
  }, [open, map.setTarget, map.getTarget, realMapRef, modalMapRef]);

  return (
    <>
      <CanvasButton
        onClick={() => setOpen(!open)}
        tooltip={<FormattedMessage id="Export FloorPlan" />}
      >
        <HiOutlineArrowDownTray className="size-5" />
      </CanvasButton>
      <Modal
        title={intl.formatMessage({
          id: 'Export FloorPlan',
        })}
        action={Action.UPDATE}
        open={open}
        setShowModal={setOpen}
        wrapperClassName="sm:max-w-(--breakpoint-sm) md:max-w-(--breakpoint-md) lg:max-w-(--breakpoint-2xl) px-2"
        footer={
          <ModalFooter
            disabled={!open}
            proceed={intl.formatMessage({
              id: 'Confirm',
            })}
            action={Action.UPDATE}
            onProceed={() => {
              canvasExport();
            }}
            onCancel={() => {
              setOpen(false);
            }}
          />
        }
      >
        <div>
          <FormattedMessage id="Center on the floor part that needs to be exported" />
          <div
            id={CANVAS_EXPORT_MAP_ID}
            className="h-screen w-full relative border border-neutral-300 dark:border-neutral-700 rounded-md"
            ref={setModalMapRef}
          >
            <div className="absolute z-10 top-0 left-0 flex flex-col gap-2 p-1">
              <ZoomButtons map={map} zoom={zoom} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
