import { Themes } from '@/common/types';
import useStore from 'model/store';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';
import CheckMark from '../Tile/CheckMark';
import TileButton from '../Tile/TileButton';

export default function ModeSwitch(): React.JSX.Element {
  const theme = useStore((state) => state.userSettings.theme);
  const setTheme = useStore((state) => state.userApi.setTheme);

  return (
    <div className="flex flex-col space-y-6 xl:space-x-6 xl:space-y-0 xl:flex-row w-auto relative">
      <TileButton
        onClick={() => {
          setTheme({
            color: window.matchMedia('(prefers-color-scheme: dark)').matches
              ? Themes.DARK
              : Themes.LIGHT,
            useDeviceTheme: true,
          });
        }}
        title={<FormattedMessage id="Device mode" />}
      >
        <div className="w-full relative">
          <div className="transition-all flex shadow-sm rounded-sm hover:shadow-lg">
            <div className="w-1/2">
              <div className="rounded-l p-4 bg-white border border-r-0 border-neutral-200">
                <div className="flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-neutral-900 rounded-sm w-3/4" />

                    <div className="h-4 bg-neutral-900 rounded-sm" />

                    <div className="h-4 bg-neutral-900 rounded-sm w-5/6" />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-1/2">
              <div className="rounded-r p-4 bg-neutral-900 border border-l-0 border-neutral-200">
                <div className="flex space-x-4">
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-white rounded-sm w-3/4" />

                    <div className="h-4 bg-white rounded-sm" />

                    <div className="h-4 bg-white rounded-sm w-5/6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {theme.useDeviceTheme && <CheckMark />}
        </div>
      </TileButton>
      {Object.values(Themes).map((color) => (
        <TileButton
          onClick={() => {
            setTheme({ color, useDeviceTheme: false });
          }}
          key={color}
          title={
            <FormattedMessage
              id={
                `${
                  color.charAt(0).toUpperCase() + color.slice(1)
                } mode` as IntlMessageKeys
              }
            />
          }
        >
          <div className="w-auto relative">
            <div
              className={`transition-all shadow border border-neutral-200 hover:shadow-lg rounded-md p-4 ${
                color === Themes.LIGHT ? 'bg-white' : 'bg-neutral-900'
              }`}
            >
              <div className="flex space-x-4">
                <div className="flex-1 space-y-4 py-1">
                  <div
                    className={`${
                      color === Themes.LIGHT ? 'bg-neutral-900' : 'bg-white'
                    } h-4 rounded w-3/4`}
                  />

                  <div
                    className={`${
                      color === Themes.LIGHT ? 'bg-neutral-900' : 'bg-white'
                    } h-4 rounded`}
                  />

                  <div
                    className={`${
                      color === Themes.LIGHT ? 'bg-neutral-900' : 'bg-white'
                    } h-4 rounded w-5/6`}
                  />
                </div>
              </div>
            </div>
            {color === theme.color && !theme.useDeviceTheme && <CheckMark />}
          </div>
        </TileButton>
      ))}
    </div>
  );
}
