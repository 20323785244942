import { Themes } from '@/common/types';
import LogoLight from '@/img/logo.svg';
import LogoDark from '@/img/logo_dark.svg';
import useStore from '@/model/store';

export default function Logo() {
  const theme = useStore((state) => state.userSettings.theme);

  return (
    <img
      key="light"
      className="max-w-lg"
      // Do not load the img from our azure storage as it will take a bit longer to load
      src={theme.color === Themes.LIGHT ? LogoLight : LogoDark}
      alt="organization logo"
    />
  );
}
