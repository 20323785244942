import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Input from '@/generic/components/Form/Input/Input';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import {
  type GroupInput,
  type KeycloakUsersQuery,
  useInsertKeycloakUserMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import { useEffect, useState } from 'react';
import { HiOutlineIdentification } from 'react-icons/hi2';
import GroupsInput from './GroupsInput';

export const generatePassword = () =>
  Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);

export type KeycloakTableUser =
  KeycloakUsersQuery['GetUsers']['groupMembers'][number];
interface AddUserModalProps {
  selectedUser?: KeycloakTableUser;
  setSelectedUser: (selectedUser?: KeycloakTableUser) => void;
  isAddingUser: boolean;
  setIsAddingUser: (addingUser: boolean) => void;
}

export default function AddUserModal({
  selectedUser,
  setSelectedUser,
  isAddingUser,
  setIsAddingUser,
}: AddUserModalProps): React.JSX.Element {
  const intl = useIntl();
  const [groupsToAdd, setGroupsToAdd] = useState<GroupInput[]>([]);
  const [groupsToDelete, setGroupsToDelete] = useState<GroupInput[]>([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(generatePassword());
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const validEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      username,
    );

  const [{ fetching: loadingAddUser }, addUser] =
    useInsertKeycloakUserMutation();

  useEffect(() => {
    if (isAddingUser) {
      setGroupsToAdd([]);
      setGroupsToDelete([]);
    }
  }, [isAddingUser]);

  return (
    <Modal
      action={isAddingUser ? Action.ADD : Action.UPDATE}
      title={intl.formatMessage(
        {
          id: isAddingUser ? 'Add user' : 'Edit user',
        },
        { name: selectedUser?.username },
      )}
      wrapperClassName="sm:max-w-(--breakpoint-sm) md:max-w-(--breakpoint-md) lg:max-w-(--breakpoint-lg)"
      open={!!selectedUser || !!isAddingUser}
      setShowModal={(val) => {
        if (selectedUser) {
          setSelectedUser(undefined);
        }
        if (isAddingUser) {
          setIsAddingUser(val);
        }
      }}
      footer={
        <ModalFooter
          disabled={
            isAddingUser &&
            (username === '' ||
              password === '' ||
              loadingAddUser ||
              !validEmail)
          }
          action={isAddingUser ? Action.ADD : Action.UPDATE}
          loading={<FormattedMessage id="Save" />}
          isLoading={loadingAddUser}
          onProceed={() => {
            if (selectedUser) {
              // Edit User
              addUser(
                {
                  User: {
                    email: selectedUser.username,
                    groupsToAdd: groupsToAdd.filter(
                      (group) =>
                        !selectedUser.groups
                          ?.map((g) => g?.id)
                          .includes(group.id),
                    ),
                    groupsToDelete: groupsToDelete.filter((group) =>
                      selectedUser.groups?.map((g) => g?.id).includes(group.id),
                    ),
                  },
                },
                hasuraHeader(HasuraPermissions.VIEW_USERGROUP, [
                  'GroupMembers',
                ]),
              ).then((data) => {
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      { id: 'Updated user' },
                      { name: selectedUser.username },
                    ),
                  },
                });
                setSelectedUser(undefined);
              });
            } else {
              addUser(
                {
                  User: {
                    email: username,
                    groupsToAdd,
                    groupsToDelete,
                    password,
                  },
                },
                hasuraHeader(HasuraPermissions.VIEW_USERGROUP),
              ).then((data) => {
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      { id: 'Added user' },
                      { name: data.data?.insert_User?.username },
                    ),
                  },
                });
                setUsername('');
                setPassword(generatePassword());
                setGroupsToAdd([]);
                setGroupsToDelete([]);
                setIsAddingUser(false);
              });
            }
          }}
          onCancel={() => {
            setIsAddingUser(false);
            setSelectedUser(undefined);
          }}
        />
      }
    >
      <div className="text-sm  text-neutral-700 dark:text-white space-y-2">
        {isAddingUser && (
          <Input
            type="email"
            value={username}
            label={intl.formatMessage({
              id: 'Email',
            })}
            placeholder={intl.formatMessage({
              id: 'Email',
            })}
            id="email"
            renderIcon={({ className }) => (
              <HiOutlineIdentification className={className} />
            )}
            onChangeValue={(e) => setUsername(e)}
            required
            data-test-id="email"
          />
        )}
        <div>
          <FormattedMessage id="User's groups" />
        </div>
        <GroupsInput
          initialUser={selectedUser}
          groupsToAdd={groupsToAdd}
          setGroupsToAdd={setGroupsToAdd}
          groupsToDelete={groupsToDelete}
          setGroupsToDelete={setGroupsToDelete}
        />
      </div>
    </Modal>
  );
}
