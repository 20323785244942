import { ClimateType, ModuleType, OtherType } from '@/common/types';
import {
  FaExpand,
  FaExpandAlt,
  FaSmog,
  FaThermometerHalf,
  FaTint,
  FaViruses,
  FaVolumeUp,
} from 'react-icons/fa';
import { HiOutlineSun, HiSignal } from 'react-icons/hi2';

const renderSensorIcon = (
  type: string,
  iconClass = 'size-5 m-2 text-neutral-700 dark:text-neutral-200',
) => {
  if (type === ClimateType.HUMIDITY) {
    return <FaTint className={iconClass} data-test-id="humidity-sensor-icon" />;
  }
  if (type === ClimateType.TEMPERATURE) {
    return (
      <FaThermometerHalf
        className={iconClass}
        data-test-id="temperature-sensor-icon"
      />
    );
  }
  if (type === ClimateType.TVOC) {
    return <FaViruses className={iconClass} data-test-id="tvoc-sensor-icon" />;
  }
  if (type === ClimateType.CO2EQ) {
    return <FaSmog className={iconClass} data-test-id="co2eq-sensor-icon" />;
  }
  if (type === ClimateType.CO2) {
    return <FaSmog className={iconClass} data-test-id="co2-sensor-icon" />;
  }
  if (type === OtherType.BRIGHTNESS) {
    return (
      <HiOutlineSun
        className={iconClass}
        data-test-id="brightness-sensor-icon"
      />
    );
  }
  if (type === ClimateType.AUDIO) {
    return (
      <FaVolumeUp className={iconClass} data-test-id="audio-sensor-icon" />
    );
  }
  if (type === ModuleType.AREACOUNT) {
    return <FaExpand className={iconClass} data-test-id="area-sensor-icon" />;
  }
  if (
    ModuleType.LINECOUNT === type ||
    ModuleType.LINECOUNT_IN === type ||
    ModuleType.LINECOUNT_OUT === type
  ) {
    return (
      <FaExpandAlt className={iconClass} data-test-id="line-sensor-icon" />
    );
  }
  return <HiSignal className={iconClass} data-test-id="other-sensor-icon" />;
};

export default renderSensorIcon;
