import { Transition as HUITransition } from '@headlessui/react';
import type { ComponentProps } from 'react';

export default function Transition({
  as = 'div',
  unmount = true,
  appear = true,
  children,
  ...rest
}: ComponentProps<typeof HUITransition>) {
  return (
    <HUITransition
      appear={appear}
      as={as}
      unmount={unmount}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-out duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      {...rest}
    >
      {children}
    </HUITransition>
  );
}
