import Accordion from '@/generic/components/Accordion';
import { FormattedMessage } from '@/translations/Intl';
import Skeleton from './components/Skeleton';

type Building = {
  Name: string;
  Floors: {
    Number: number;
  }[];
};

interface BuildingFloorListProps<T extends Building> {
  loading: boolean;
  buildings: T[];
  renderMap: (building: T, floor: T['Floors'][number]) => React.JSX.Element;
  renderTable: (
    building: T,
    floor: T['Floors'][number],
  ) => React.JSX.Element | undefined;
  renderAdditionalContent?: (
    building: T,
    floor: T['Floors'][number],
  ) => React.JSX.Element | undefined;
  getTestId?: (building: T) => string;
}

export default function BuildingFloorList<T extends Building>({
  loading,
  getTestId,
  buildings,
  renderMap,
  renderTable,
  renderAdditionalContent,
}: BuildingFloorListProps<T>) {
  return loading ? (
    <Skeleton loading={loading} />
  ) : (
    <div className="space-y-4">
      {buildings.map((building) => (
        <Accordion key={building.Name} title={building.Name} initialStateOpen>
          <div
            data-test-id={getTestId?.(building)}
            className="space-y-4 divide-y-2 divide-dashed print:divide-none divide-neutral-200 dark:divide-neutral-700"
          >
            {building.Floors.map((floor) => (
              <div
                key={floor.Number}
                className="flex flex-col not-first:pt-4 print:break-after-page"
              >
                <div className="font-bold print:hidden">
                  <FormattedMessage
                    id="{number} Floor"
                    values={{ number: floor.Number }}
                  />
                </div>
                <div className="font-bold hidden print:block">
                  <FormattedMessage
                    id="Building Floor"
                    values={{
                      building: building.Name,
                      number: floor.Number,
                    }}
                  />
                </div>
                {renderAdditionalContent?.(building, floor)}
                <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 print:flex-col! md:space-x-2 print:space-y-2 print:space-x-0 justify-around">
                  <div className="w-full h-96 md:w-96 print:w-full relative">
                    {renderMap(building, floor)}
                  </div>
                  <div className="w-full md:max-w-3xl print:w-full">
                    {renderTable(building, floor)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Accordion>
      ))}
    </div>
  );
}
