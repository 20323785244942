import { ModuleType, Themes, isNonNullable } from '@/common/types';
import { RAINBOW } from '@/constants';
import AnimatedPath from '@/generic/components/AnimatedPath';
import Axis from '@/generic/components/Chart/Axis';
import Switch from '@/generic/components/Form/Switch';
import LoadingSpinner from '@/generic/components/LoadingSpinner';
import Transition from '@/generic/components/Transition';
import type { RoomOccupancySensorCountQuery } from '@/graphql/types';
import { dateAtUTC } from '@/utils/date';
import localize from '@/utils/format';
import getColor from '@/utils/getColor';
import { curveBasis } from '@visx/curve';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { Bar, LinePath } from '@visx/shape';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { extent } from 'd3-array';
import Legend from 'generic/components/Chart/Legend';
import useStore from 'model/store';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'translations/Intl';

interface ResponsiveRoomOccupancySensorCountProps {
  margin?: { top: number; right: number; bottom: number; left: number };
  roomOccupancySensorCount?: RoomOccupancySensorCountQuery['f_history_room_occupancy_15m'];
  loadingRoomOccupancySensorCount: boolean;
  hideLegend?: boolean;
}

interface RoomOccupancySensorCountProps
  extends ResponsiveRoomOccupancySensorCountProps {
  width: number;
  height: number;
}

type TooltipData = { date: Date; name: string; value: number };

const renderKey = (
  d:
    | RoomOccupancySensorCountQuery['f_history_room_occupancy_15m'][number]
    | {
        Date: Date;
        SensorType: string;
        Value: number;
        BeaconName?: string;
        Index?: number;
      },
) => {
  let sensorKey = 'area';
  if (d.SensorType !== ModuleType.AREACOUNT) {
    sensorKey = d.SensorType === ModuleType.LINECOUNT_IN ? 'in' : 'out';
  }
  const { BeaconName, Index } = d;
  return `${BeaconName || ''}-${sensorKey}-${
    typeof Index === 'number' ? Index : ''
  }`;
};

const TOTAL_SENSOR_TYPE = 'total';

const sortSensors = (a: string, b: string) => {
  const [beaconNameA, sensorTypeA, indexA] = a.split('-');
  const [beaconNameB, sensorTypeB, indexB] = b.split('-');
  return (
    (beaconNameA || '').localeCompare(beaconNameB || '') ||
    Number.parseInt(indexA ?? '0', 10) - Number.parseInt(indexB ?? '0', 10) ||
    (sensorTypeA || '').localeCompare(sensorTypeB || '')
  );
};

const formatDate = (date: Date) => localize(date, 'eeeeee do LLL, p');

const dateAccessor = (d: { Date: Date }) => dateAtUTC(d.Date);

function RoomOccupancySensorCount({
  width,
  height,
  margin = {
    top: 70,
    left: 60,
    bottom: 40,
    right: 60,
  },
  roomOccupancySensorCount,
  loadingRoomOccupancySensorCount,
  hideLegend,
}: RoomOccupancySensorCountProps) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);
  const room = useStore((state) => state.userSettings.room);
  const [lineCount, setLineCount] = useState(false);
  const [numTicks] = useState(4);

  const sensorsData = useMemo(
    () =>
      roomOccupancySensorCount && roomOccupancySensorCount.length > 0
        ? roomOccupancySensorCount
            .map((sensorData) => ({
              ...sensorData,
              Date: new Date(sensorData.Date),
            }))
            .sort((a, b) => sortSensors(renderKey(a), renderKey(b)))
        : [
            {
              Id: 0,
              SensorId: 0,
              SensorType: ModuleType.LINECOUNT_IN,
              Index: 0,
              Value: 0,
              BeaconName: 'Default',
              Sign: 1,
              Date: new Date(),
            },
          ],
    [roomOccupancySensorCount],
  );

  const lineSensorTypes = useMemo<string[]>(
    () => [
      ...new Set(
        sensorsData
          .filter((d) => d.SensorType !== ModuleType.AREACOUNT)
          .map((d) => renderKey(d)),
      ),
    ],
    [sensorsData],
  );

  const areaSensorTypes = useMemo<string[]>(
    () => [
      ...new Set(
        sensorsData
          .filter((d) => d.SensorType === ModuleType.AREACOUNT)
          .map((d) => renderKey(d)),
      ),
    ],
    [sensorsData],
  );

  const summedRoomData = useMemo(() => {
    const lineSensorsData = sensorsData.filter(
      (sD) => sD.SensorType !== ModuleType.AREACOUNT,
    );

    const areaSensorsData = sensorsData.filter(
      (sD) => sD.SensorType === ModuleType.AREACOUNT,
    );

    return [...new Set(lineSensorsData.map((sD) => sD.Date.getTime()))].map(
      (date) => ({
        Date: new Date(date),
        SensorType: TOTAL_SENSOR_TYPE,
        Value: lineCount
          ? lineSensorsData
              .filter((sD) => sD.Date.getTime() === date)
              .reduce(
                (acc, curr) =>
                  acc +
                  curr.Value *
                    (curr.Sign *
                      (curr.SensorType === ModuleType.LINECOUNT_IN ? 1 : -1)),
                0,
              )
          : areaSensorsData
              .filter((sD) => sD.Date.getTime() === date)
              .reduce((acc, curr) => acc + curr.Value, 0),
      }),
    );
  }, [sensorsData, lineCount]);

  useEffect(() => {
    // Only check if there is no data after data is loaded
    // otherwise it will switch when it is loading
    if (loadingRoomOccupancySensorCount) return;
    if (areaSensorTypes.length === 0) {
      setLineCount(true);
    }
    if (lineSensorTypes.length === 0) {
      setLineCount(false);
    }
  }, [areaSensorTypes, lineSensorTypes, loadingRoomOccupancySensorCount]);

  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  const sensors = summedRoomData.length
    ? [...(lineCount ? lineSensorTypes : areaSensorTypes), room?.Name ?? '']
    : [...(lineCount ? lineSensorTypes : areaSensorTypes)];

  const dates = sensorsData
    .filter(
      (date, i, self) =>
        self.findIndex((d) => d.Date.getTime() === date.Date.getTime()) === i,
    )
    .map(dateAccessor);

  const xScale = scaleTime<number>({
    domain: extent(sensorsData, dateAccessor) as [Date, Date],
    range: [0, xMax],
  });

  const xScaleBand = scaleBand<Date>({
    domain: dates,
    range: [0, xMax],
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [
      Math.min(...sensorsData.map((c) => c.Value)),
      Math.max(...sensorsData.map((c) => c.Value)),
    ],
  });

  const y2Scale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [
      Math.min(...summedRoomData.map((c) => c.Value)),
      Math.max(...summedRoomData.map((c) => c.Value)),
    ],
  });

  const colorScale = scaleOrdinal({
    domain: sensors,
    range: RAINBOW,
  });

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData[]>();

  return (
    <>
      <LoadingSpinner loading={loadingRoomOccupancySensorCount} />
      <div className="relative" data-test-id="room-line-count">
        {areaSensorTypes.length > 0 && lineSensorTypes.length > 0 && (
          <div className="flex space-x-2 items-center absolute right-2 top-2 text-xs">
            <div>
              <FormattedMessage id="clc_areacount" />
            </div>
            <Switch
              isEnabled={lineCount}
              onSetEnable={setLineCount}
              disabledColor="bg-green-500"
              data-test-id="line-count-switch"
            />
            <div>
              <FormattedMessage id="In / Out" />
            </div>
          </div>
        )}
        <svg width={width} height={height}>
          <Group top={margin.top} left={margin.left}>
            <GridRows
              numTicks={10}
              scale={yScale}
              width={xMax}
              height={yMax}
              strokeDasharray="1,3"
              stroke={getColor('NEUTRAL600')}
              strokeOpacity={0.6}
            />
            {lineCount &&
              lineSensorTypes.map((sensorData) => (
                <LinePath
                  key={sensorData}
                  data={sensorsData.filter(
                    (sD) => renderKey(sD) === sensorData,
                  )}
                  curve={curveBasis}
                  x={(d) => xScale(dateAccessor(d)) ?? 0}
                  y={(d) => yScale(d.Value) ?? 0}
                >
                  {({ path }) => (
                    <AnimatedPath
                      path={path}
                      data={sensorsData.filter(
                        (sD) => renderKey(sD) === sensorData,
                      )}
                      stroke={colorScale(sensorData)}
                      strokeOpacity={0.6}
                    />
                  )}
                </LinePath>
              ))}
            {!lineCount &&
              areaSensorTypes.map((sensorData) => (
                <LinePath
                  key={sensorData}
                  data={sensorsData.filter(
                    (sD) => renderKey(sD) === sensorData,
                  )}
                  curve={curveBasis}
                  x={(d) => xScale(dateAccessor(d)) ?? 0}
                  y={(d) => yScale(d.Value) ?? 0}
                >
                  {({ path }) => (
                    <AnimatedPath
                      path={path}
                      data={sensorsData.filter(
                        (sD) => renderKey(sD) === sensorData,
                      )}
                      stroke={colorScale(sensorData)}
                      strokeOpacity={0.6}
                    />
                  )}
                </LinePath>
              ))}
            {summedRoomData.length > 0 && (
              <LinePath
                data={summedRoomData}
                curve={curveBasis}
                x={(d) => xScale(dateAccessor(d)) ?? 0}
                y={(d) => y2Scale(d.Value) ?? 0}
              >
                {({ path }) => (
                  <AnimatedPath
                    path={path}
                    data={summedRoomData}
                    stroke={colorScale(room?.Name ?? '')}
                    strokeWidth={3}
                  />
                )}
              </LinePath>
            )}
            {
              // Just used for showing the tooltip
              dates.map((date, idx) => (
                <Bar
                  key={idx}
                  // No idea where the 15 comes from, otherwise it is too narrow
                  width={xScaleBand.bandwidth() + 15}
                  x={xScale(date) ?? 0}
                  height={yMax}
                  fill="transparent"
                  data-test-id={`room-line-count-${formatDate(date)}`}
                  onMouseMove={(event) => {
                    const point = localPoint(event);
                    if (point?.x) {
                      showTooltip({
                        tooltipData: [
                          ...sensorsData
                            .filter(
                              (d) =>
                                dateAccessor(d).valueOf() === date.valueOf() &&
                                sensors.includes(renderKey(d)),
                            )
                            .map((d) => ({
                              date: dateAccessor(d),
                              name: renderKey(d),
                              value: d.Value,
                            })),
                          summedRoomData.length
                            ? {
                                date,
                                name: room?.Name ?? '',
                                value:
                                  summedRoomData.find(
                                    (d) =>
                                      dateAccessor(d).valueOf() ===
                                      date.valueOf(),
                                  )?.Value ?? 0,
                              }
                            : undefined,
                        ].filter(isNonNullable),
                        tooltipTop: point?.y,
                        tooltipLeft: point.x,
                      });
                    }
                  }}
                  onMouseOut={hideTooltip}
                />
              ))
            }
            <Axis
              lowLevelChart
              orientation="left"
              scale={yScale}
              numTicks={numTicks}
              label={
                lineCount
                  ? intl.formatMessage({ id: 'In / Out' })
                  : intl.formatMessage({ id: 'clc_areacount' })
              }
              labelOffset={25}
            />
            {summedRoomData.length && (
              <Axis
                lowLevelChart
                orientation="right"
                scale={y2Scale}
                left={xMax}
                numTicks={numTicks}
                label={intl.formatMessage({ id: 'Total' })}
                labelOffset={25}
              />
            )}
            <Axis
              lowLevelChart
              orientation="bottom"
              scale={xScale}
              numTicks={width > 768 ? 5 : 2}
              top={yMax}
            />
          </Group>
        </svg>
        <Transition show={!hideLegend}>
          <div className="absolute w-full flex flex-wrap justify-center top-8 text-xs">
            <Legend scaleType="ordinal" scale={colorScale} />
          </div>
        </Transition>
        {tooltipOpen && tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              background:
                theme.color === Themes.LIGHT
                  ? getColor('WHITE')
                  : getColor('NEUTRAL900'),
            }}
            data-test-id="room-line-count-tooltip"
          >
            <div className="text-primary-500">
              <strong>{formatDate(tooltipData[0]?.date ?? new Date())}</strong>
            </div>
            {tooltipData.map((d) => (
              <div key={d.name}>
                <strong style={{ color: colorScale(d.name) }}>{d.name}</strong>{' '}
                {d.value.toFixed(0)}
              </div>
            ))}
          </TooltipWithBounds>
        )}
      </div>
    </>
  );
}

export default function ResponsiveRoomOccupancySensorCount(
  props: ResponsiveRoomOccupancySensorCountProps,
) {
  return (
    <ParentSize>
      {({ width, height }) => (
        <RoomOccupancySensorCount {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
