import useStore from 'model/store';
import { FormattedMessage, useIntl } from 'translations/Intl';

import Card from '@/generic/components/Card';
import DurationMessage from '@/generic/components/DurationMessage';
import StyledButton from '@/generic/components/Form/Button/StyledButton';
import Subtitle from '@/generic/components/Subtitle';
import Transition from '@/generic/components/Transition';
import {
  type BuildingPartsFragment,
  type FloorPartsFragment,
  useFavoriteDeskAndRoomQuery,
  useInsertDeskReservationMutation,
  useInsertRoomReservationMutation,
} from '@/graphql/types';
import { lower, serializeRange, upper } from '@/utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useRoomDeskFilter from '@/utils/graphql/useRoomDeskFilter';
import useToast from '@/utils/graphql/useToast';
import { HiMap, HiStar, HiTableCells } from 'react-icons/hi2';

interface FavoriteReservationProps {
  reservationStartDate: Date;
  reservationEndDate: Date;
}

function Reserved({
  type,
  name,
  building,
  floor,
  isReserved,
  reservationsDuration,
  reserveAction,
  fetching,
  numberOfTimesReserved,
}: {
  type: 'Desk' | 'Room';
  name: string;
  building?: BuildingPartsFragment;
  floor?: FloorPartsFragment;
  isReserved: boolean;
  reservationsDuration: string;
  reserveAction: () => void;
  fetching: boolean;
  numberOfTimesReserved: number;
}) {
  const intl = useIntl();
  const setBuilding = useStore((state) => state.userApi.setBuilding);
  const setFloor = useStore((state) => state.userApi.setFloor);
  const setSelectedFeature = useStore((state) => state.setSelectedFeature);

  return (
    <Card noPadding className="md:p-2 px-2 py-2 w-full md:w-fit">
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col">
          <div className="flex items-center space-x-1">
            <HiStar className="size-5 text-yellow-400" />
            <Subtitle value={intl.formatMessage({ id: `Favorite ${type}` })} />
          </div>
          <div>
            {fetching ? (
              <div className="animate-pulse items-center bg-neutral-200 h-6 w-16 rounded-md" />
            ) : (
              <div className="flex flex-col items-start">
                <div className="flex items-center space-x-1">
                  <div className="font-bold">{name}</div>
                  <div className="text-sm">
                    <FormattedMessage
                      id="Building Floor"
                      values={{
                        building: building?.Name,
                        number: floor?.Number,
                      }}
                    />
                  </div>
                </div>
                {isReserved && (
                  <div className="text-sm">
                    <FormattedMessage id="Not available" />.{' '}
                    <DurationMessage
                      start={lower(reservationsDuration)}
                      end={upper(reservationsDuration)}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col space-y-1 justify-between md:justify-start">
          <div className="text-sm">
            <FormattedMessage
              id="Reserved times"
              values={{ number: numberOfTimesReserved }}
            />
          </div>
          <div className="flex flex-row space-x-1 justify-between md:justify-start">
            <StyledButton
              className="py-1 px-1 md:py-1 md:px-2 flex items-center w-full md:w-fit"
              disabled={isReserved}
              onClick={() => reserveAction()}
            >
              <HiTableCells className="size-5 mr-2" />
              <FormattedMessage id="Reserve it" />
            </StyledButton>
            <StyledButton
              className="py-1 px-1 md:py-1 md:px-2 flex items-center w-full md:w-fit"
              onClick={() => {
                if (floor) setFloor(floor);
                if (building) setBuilding(building);
                setSelectedFeature(name);
              }}
            >
              <HiMap className="size-5 mr-2" />
              <FormattedMessage id="Show" />
            </StyledButton>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default function FavoriteReservation({
  reservationStartDate,
  reservationEndDate,
}: FavoriteReservationProps) {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [, addDeskReservation] = useInsertDeskReservationMutation();
  const [, addRoomReservation] = useInsertRoomReservationMutation();
  const [{ data, fetching }] = useFavoriteDeskAndRoomQuery({
    variables: {
      ...useRoomDeskFilter(),
    },
  });

  const desks = data?.DeskReservations?.map((desk) => desk.Desk) ?? [];
  const rooms = data?.RoomReservations?.map((room) => room.Room) ?? [];

  const duration = serializeRange({
    start: {
      value: reservationStartDate,
      inclusive: true,
    },
    end: {
      value: reservationEndDate,
      inclusive: false,
    },
  });

  const favoriteDesk = desks
    .sort(
      (a, b) =>
        desks.filter((v) => v.Id === a.Id).length -
        desks.filter((v) => v.Id === b.Id).length,
    )
    .pop();

  const favoriteRoom = rooms
    .sort(
      (a, b) =>
        rooms.filter((v) => v.Id === a.Id).length -
        rooms.filter((v) => v.Id === b.Id).length,
    )
    .pop();

  const favoriteDeskCurrentReservations =
    data?.DeskReservations?.filter(
      (desk) =>
        desk.Desk.Id === favoriteDesk?.Id &&
        // Check if the overlap -> true if overlapping -> room is reserved
        lower(desk.Duration) <= reservationEndDate &&
        upper(desk.Duration) >= reservationStartDate,
    ) ?? [];

  const favoriteRoomCurrentReservations =
    data?.RoomReservations?.filter(
      (room) =>
        room.Room.Id === favoriteRoom?.Id &&
        // Check if the overlap -> true if overlapping -> room is reserved
        lower(room.Duration) <= reservationEndDate &&
        upper(room.Duration) >= reservationStartDate,
    ) ?? [];

  const favoriteDeskNumberOfReservations =
    data?.DeskReservations?.filter((desk) => desk.Desk.Id === favoriteDesk?.Id)
      .length ?? 0;

  const favoriteRoomNumberOfReservations =
    data?.RoomReservations?.filter((room) => room.Room.Id === favoriteRoom?.Id)
      .length ?? 0;

  const favoriteDeskIsReserved = favoriteDeskCurrentReservations.length > 0;
  const favoriteRoomIsReserved = favoriteRoomCurrentReservations.length > 0;

  return (
    <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
      <Transition show={!!favoriteDesk} className="flex">
        <Reserved
          fetching={fetching}
          type="Desk"
          name={favoriteDesk?.Name ?? ''}
          building={favoriteDesk?.Sensor?.RoomSensors[0]?.Room.Floor.Building}
          floor={favoriteDesk?.Sensor?.RoomSensors[0]?.Room.Floor}
          isReserved={favoriteDeskIsReserved}
          reservationsDuration={
            favoriteDeskCurrentReservations[0]
              ? favoriteDeskCurrentReservations[0]?.Duration
              : '[00:00:00, 01:00:00]'
          }
          numberOfTimesReserved={favoriteDeskNumberOfReservations}
          reserveAction={() =>
            addDeskReservation(
              {
                Desk: [
                  {
                    DeskId: favoriteDesk?.Id,
                    Duration: duration,
                  },
                ],
              },
              hasuraHeader(HasuraPermissions.READ, [
                'DeskReservations_aggregate',
              ]),
            ).then((message) => {
              toast(message, {
                message: {
                  type: 'success',
                  content: intl.formatMessage(
                    {
                      id: 'Added reservation for desk',
                    },
                    { desk: favoriteDesk?.Name },
                  ),
                },
              });
            })
          }
        />
      </Transition>
      <Transition show={!!favoriteRoom} className="flex">
        <Reserved
          fetching={fetching}
          type="Room"
          name={favoriteRoom?.Name ?? ''}
          building={favoriteRoom?.Floor.Building}
          floor={favoriteRoom?.Floor}
          isReserved={favoriteRoomIsReserved}
          reservationsDuration={
            favoriteRoomCurrentReservations[0]
              ? favoriteRoomCurrentReservations[0]?.Duration
              : '[00:00:00, 01:00:00]'
          }
          numberOfTimesReserved={favoriteRoomNumberOfReservations}
          reserveAction={() => {
            addRoomReservation(
              {
                Room: [
                  {
                    RoomId: favoriteRoom?.Id,
                    Duration: duration,
                  },
                ],
              },
              hasuraHeader(HasuraPermissions.READ, [
                'RoomReservations_aggregate',
              ]),
            ).then((message) => {
              toast(message, {
                message: {
                  type: 'success',
                  content: intl.formatMessage(
                    {
                      id: 'Added reservation for room',
                    },
                    { room: favoriteRoom?.Name },
                  ),
                },
              });
            });
          }}
        />
      </Transition>
    </div>
  );
}
