import { Action } from '@/common/types';
import Input from '@/generic/components/Form/Input/Input';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import {
  useInsertSensorPolicyMutation,
  useSensorTypesQuery,
} from '@/graphql/types';
import {
  Mda2MessageType,
  generateMda2Topic,
  isMda2Topic,
} from '@/pages/AdminView/BuildingView/components/FloorList/components/FloorRoomView/components/FloorRoomMap/components/SelectNewBeaconCard/components/EnrollDevices/components/TopicCell/TopicCell';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import parseMda2Topic from '@/utils/parseMda2Topic';
import TextArea from 'generic/components/Form/TextArea/TextArea';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import { useEffect, useState } from 'react';
import { HiOutlineIdentification } from 'react-icons/hi2';
import { FormattedMessage, useIntl } from 'translations/Intl';
import type { SensorPolicy } from '../RemoveSensorPolicyModal/RemoveSensorPolicyModal';
import SensorSelect, { DEBUG } from './components/SensorSelect';

interface AddSensorPolicyModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedPolicy?: SensorPolicy;
  setSelectedPolicy: (selectedPolicy?: SensorPolicy) => void;
}

export default function AddSensorPolicyModal({
  open,
  setOpen,
  selectedPolicy,
  setSelectedPolicy,
}: AddSensorPolicyModalProps) {
  const intl = useIntl();
  const [name, setName] = useState(selectedPolicy?.Name ?? '');
  const [configuration, setConfiguration] = useState<{
    [key: string]: number | boolean | string;
  }>({});
  const [debugInterval, setDebugInterval] = useState(60);
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();

  const [{ data: sensorTypes }] = useSensorTypesQuery();
  const [{ fetching: loadingAddSensorPolicy }, addSensorPolicy] =
    useInsertSensorPolicyMutation();

  useEffect(() => {
    if (selectedPolicy) {
      setName(selectedPolicy.Name);
      setConfiguration(selectedPolicy.Configuration);
      setDebugInterval(
        typeof selectedPolicy.Configuration.debug_interval_s === 'number'
          ? selectedPolicy.Configuration.debug_interval_s
          : 0,
      );
    } else {
      setName('');
    }
  }, [selectedPolicy]);

  // Create the default configuration if there is none yet
  useEffect(() => {
    if (sensorTypes?.SensorTypes && !name) {
      setConfiguration({
        ...Object.assign(
          {},
          ...sensorTypes.SensorTypes.map((s) => ({
            [`${s.Name.replace('clc_', '')}_send_on_change`]:
              s.MinimumUpdateThreshold,
            [`${s.Name.replace('clc_', '')}_interval_s`]: 0,
          })),
        ),
        debug_interval_s: debugInterval,
      });
    }
  }, [name, sensorTypes, sensorTypes?.SensorTypes, debugInterval]);

  const resetValues = () => {
    setOpen(false);
    setSelectedPolicy(undefined);
  };

  return (
    <Modal
      action={selectedPolicy ? Action.UPDATE : Action.ADD}
      title={intl.formatMessage(
        {
          id: selectedPolicy ? 'Edit sensor policy' : 'Add sensor policy',
        },
        { name: selectedPolicy?.Name },
      )}
      open={open}
      wrapperClassName="sm:max-w-(--breakpoint-sm) md:max-w-(--breakpoint-md) lg:max-w-(--breakpoint-lg)"
      setShowModal={resetValues}
      footer={
        <ModalFooter
          disabled={name === ''}
          action={selectedPolicy ? Action.UPDATE : Action.ADD}
          loading={<FormattedMessage id="Save" />}
          isLoading={loadingAddSensorPolicy}
          onProceed={() => {
            addSensorPolicy(
              {
                Update: !!selectedPolicy?.Id,
                Policy: {
                  Id: selectedPolicy?.Id,
                  Name: name,
                  Configuration: configuration,
                },
                UserInput: {
                  // When adding a new policy the sensorPolicyId doesn't matter,
                  // as no devices will have the policy yet
                  // It will also only be called when the policy is updated
                  sensorPolicyId: selectedPolicy?.Id ?? 0,
                  skipResponseTopic: true,
                  // Update existing beacons that have applied this policy
                  topics:
                    selectedPolicy?.MqttBeacons.map((b) =>
                      isMda2Topic(b.MqttTopic)
                        ? generateMda2Topic(
                            b.MqttTopic,
                            Mda2MessageType.ADMINCMD,
                          )
                        : `cmd/${parseMda2Topic(b.MqttTopic)}/configure`,
                    ) ?? [],
                },
              },
              hasuraHeader(HasuraPermissions.WRITE_ORGANIZATION),
            ).then((data) => {
              toast(data, {
                message: {
                  type: 'success',
                  content: intl.formatMessage(
                    {
                      id: selectedPolicy
                        ? 'Updated sensor policy'
                        : 'Added sensor policy',
                    },
                    {
                      name,
                    },
                  ),
                },
              });
              if (!data.error) {
                resetValues();
              }
            });
          }}
          onCancel={resetValues}
        />
      }
    >
      <div className="text-sm text-neutral-700 dark:text-white space-y-4">
        <Input
          data-test-id="policy-name-input"
          type="text"
          value={name}
          label={intl.formatMessage({
            id: 'Name',
          })}
          placeholder={intl.formatMessage({
            id: 'Name',
          })}
          renderIcon={({ className }) => (
            <HiOutlineIdentification className={className} />
          )}
          onChangeValue={setName}
          required
        />
        <div className="grid grid-cols-4 gap-2">
          {sensorTypes?.SensorTypes.map((sensorType) => (
            <SensorSelect
              key={`${sensorType.Name}-${name}`}
              configuration={configuration}
              sensorType={sensorType}
              setConfiguration={(config) =>
                setConfiguration((prev) => ({ ...prev, ...config }))
              }
            />
          ))}
          <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
            <SensorSelect
              configuration={configuration}
              disableThreshold
              sensorType={{
                DefaultUpdateThreshold: 30,
                Name: DEBUG,
                MaxValue: 30,
                MinimumUpdateThreshold: 30,
                Unit: 's',
              }}
              setConfiguration={(config) =>
                setConfiguration((prev) => ({ ...prev, ...config }))
              }
            />
          </PrivateWrapper>
        </div>
        <TextArea
          data-test-id="configuration-text-area"
          text={JSON.stringify(
            {
              ...Object.keys(configuration)
                .sort()
                .reduce(
                  (r, k) => Object.assign(r, { [k]: configuration[k] }),
                  {},
                ),
            },
            undefined,
            4,
          )}
          label={intl.formatMessage({
            id: 'Configuration',
          })}
        />
      </div>
    </Modal>
  );
}
