import { Action } from '@/common/types';
import Button from '@/generic/components/Form/Button';
import Input from '@/generic/components/Form/Input';
import LoadingSpinner from '@/generic/components/LoadingSpinner';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import { autoUpdate, flip, shift, useFloating } from '@floating-ui/react';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOptions,
} from '@headlessui/react';
import Subtitle from 'generic/components/Subtitle/Subtitle';
import Tooltip from 'generic/components/Tooltip';
import { type Key, useEffect, useState } from 'react';
import {
  HiCheck,
  HiOutlineChevronUpDown,
  HiOutlinePlusCircle,
  HiOutlineTag,
  HiOutlineTrash,
  HiOutlineXMark,
} from 'react-icons/hi2';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import Option from './Option';

export interface EditValue {
  newValue: string;
  keyParameter: number;
}

export interface SelectProps<T> {
  value: T;
  onChangeSelected: (val: T | null) => void;
  options: T extends any[] ? T : T[];
  isOptionDisabled?: (option: T) => boolean;
  label?: IntlMessageKeys;
  defaultValue?: React.JSX.Element | string;
  isDeselectable?: boolean;
  renderValue: (
    val: T extends any[] ? T[number] : T,
  ) => string | React.JSX.Element;
  keyParameter?: T extends any[] ? keyof T[number] : keyof T;
  className?: string;
  required?: boolean;
  dataTestId?: string;
  optionDataTestId?: (option: T) => string;
  hidePreview?: boolean;
  onOptionHover?: (option: T, enter: boolean) => void;
  onOptionAdd?: (option: string) => Promise<any> | undefined;
  onOptionEdit?: (
    option: string,
    oldOption: string,
  ) => Promise<any> | undefined;
  onOptionDelete?: (option: string) => Promise<any> | undefined;
  optionPlaceholder?: string;
  tooltipText?: string;
}

export default function Select<T>({
  value,
  onChangeSelected,
  options,
  isOptionDisabled,
  label,
  defaultValue = '',
  isDeselectable = true,
  renderValue,
  keyParameter,
  className = '',
  required = false,
  dataTestId = 'select-button',
  optionDataTestId,
  onOptionHover,
  onOptionAdd,
  onOptionDelete,
  onOptionEdit,
  hidePreview,
  optionPlaceholder,
  tooltipText,
}: SelectProps<T>) {
  const intl = useIntl();
  const [newOption, setNewOption] = useState<string>('');
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isAddingLoading, setIsAddingLoading] = useState<boolean>(false);
  const [optionToEdit, setOptionToEdit] = useState<string | null>();
  const [isEditingLoading, setIsEditingLoading] = useState<boolean>(false);
  const [optionToDelete, setOptionToDelete] = useState<string | null>();
  const [isDeletingLoading, setIsDeletingLoading] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState('');

  const onInputChange = (val: T | null) => {
    if (isDeselectable && value === val) {
      onChangeSelected(null);
    } else {
      onChangeSelected(val);
    }
  };

  const { x, y, refs, strategy } = useFloating({
    placement: 'bottom-start',
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    if (optionToEdit) {
      setNewOption(optionToEdit);
    }
  }, [optionToEdit]);

  return (
    <>
      <Listbox
        value={value}
        onChange={onInputChange}
        multiple={Array.isArray(value)}
      >
        <div className={`${label ? 'flex flex-col' : ''} ${className ?? ''}`}>
          {label && (
            <Label className="text-base md:text-sm text-neutral-700 dark:text-white pb-0.5 flex space-x-1 items-center">
              <div>
                <FormattedMessage id={label} />
              </div>
              {required && <div className="text-red-700"> *</div>}
              {tooltipText && (
                <div>
                  <Tooltip>{tooltipText}</Tooltip>
                </div>
              )}
            </Label>
          )}
          <ListboxButton
            ref={refs.setReference}
            data-test-id={dataTestId}
            className={`${
              options.length === 0
                ? 'bg-neutral-200 dark:bg-neutral-700 cursor-default'
                : ''
            } cursor-pointer font-medium bg-white text-neutral-700 hover:bg-neutral-50 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-white relative py-2 pl-3 text-left text-sm border border-solid rounded-md ${
              onOptionAdd ? 'pr-16' : 'pr-10'
            }`}
          >
            {
              // Only show for multiselect
              Array.isArray(value) ? (
                hidePreview ? (
                  <FormattedMessage id="Columns" />
                ) : (
                  <div className="flex gap-1 flex-wrap">
                    {value.length > 0 ? (
                      value.slice(0, 3).map((option) => (
                        <span
                          key={
                            keyParameter
                              ? (option[keyParameter] as Key)
                              : (option as Key)
                          }
                          data-test-id={`select-option-${
                            keyParameter
                              ? (option[keyParameter] as Key)
                              : (option as Key)
                          }`}
                          className="flex w-fit items-center gap-1 rounded-sm bg-primary-500 dark:bg-primary-600 text-white px-2 py-0.5"
                        >
                          <span>{renderValue(option)}</span>
                          <HiOutlineXMark
                            data-test-id={`deselect-option-${
                              keyParameter
                                ? (option[keyParameter] as Key)
                                : (option as Key)
                            }`}
                            className="size-4 cursor-pointer"
                            onClick={() =>
                              onChangeSelected(
                                value.filter((v) => v !== option) as T,
                              )
                            }
                          />
                        </span>
                      ))
                    ) : (
                      <FormattedMessage id="Nothing selected" />
                    )}
                    {value.length > 3 && (
                      <span className="flex w-fit items-center gap-1 rounded-sm bg-primary-500 dark:bg-primary-600 text-white px-2 py-0.5">
                        <FormattedMessage
                          id="more"
                          values={{ elements: value.length - 3 }}
                        />
                      </span>
                    )}
                  </div>
                )
              ) : typeof value === 'number' || value ? (
                // TODO: Fix this type
                renderValue(value as any)
              ) : (
                defaultValue || <FormattedMessage id="Nothing selected" />
              )
            }
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <HiOutlineChevronUpDown
                className="size-5 text-neutral-400 dark:text-white"
                aria-hidden="true"
              />
            </span>
            {!!onOptionAdd && (
              // Needs to be a div because a button can't be a descendant of another button
              <div
                onClick={(e) => {
                  setIsAdding(!isAdding);
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onKeyDown={(e) => {
                  setIsAdding(!isAdding);
                  e.stopPropagation();
                  e.preventDefault();
                }}
                data-test-id={`${dataTestId}-add-button`}
                className="absolute inset-y-0 right-6 size-7 m-1 flex items-center justify-center pointer-cursor hover:bg-neutral-200 dark:hover:bg-neutral-700 rounded-full"
              >
                <HiOutlinePlusCircle
                  className="size-6 text-neutral-400 dark:text-white"
                  title={intl.formatMessage({
                    id: 'Add Label',
                  })}
                />
              </div>
            )}
          </ListboxButton>
          {!isAdding && !optionToEdit && (
            <ListboxOptions
              modal={false}
              anchor="bottom start"
              // TODO: Readd Transition see: https://github.com/tailwindlabs/headlessui/issues/3369
              // transition
              className="w-max mt-1 z-40 overflow-y-auto dark:ring-neutral-700 bg-white dark:bg-neutral-800 rounded-md shadow-lg max-h-60 ring-1 ring-black/5  focus:outline-hidden text-base md:text-sm transition duration-200 ease-out data-closed:scale-95 data-closed:opacity-0"
            >
              {options.map((option) => (
                <Option<T>
                  key={
                    keyParameter
                      ? (option[keyParameter] as Key)
                      : (option as Key)
                  }
                  dataTestId={optionDataTestId}
                  option={option}
                  renderValue={renderValue}
                  disabled={isOptionDisabled ? isOptionDisabled(option) : false}
                  onOptionHover={onOptionHover}
                  canEdit={!!onOptionEdit}
                  canDelete={!!onOptionDelete}
                  setOptionToEdit={(opt) => setOptionToEdit(opt)}
                  setOptionToDelete={setOptionToDelete}
                />
              ))}
            </ListboxOptions>
          )}
        </div>
      </Listbox>
      {(isAdding || optionToEdit) && (
        <div
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y,
            left: x,
          }}
          className="w-max mt-1 z-40 overflow-y-auto dark:ring-neutral-700 bg-white dark:bg-neutral-800 rounded-md shadow-lg max-h-60 ring-1 ring-black/5  focus:outline-hidden text-base md:text-sm flex items-center justify-center p-2 gap-2"
        >
          <Input
            type="text"
            placeholder={optionPlaceholder}
            value={newOption}
            renderIcon={({ className }) => (
              <HiOutlineTag className={className} />
            )}
            className="w-full dark:bg-neutral-800 focus:ring-primary-500 dark:focus:ring-neutral-500 focus:border-primary-500 dark:focus:border-neutral-500 block border-neutral-200 dark:border-neutral-700 text-base md:text-sm rounded-md disabled:opacity-50 "
            onChangeValue={setNewOption}
          />
          {(onOptionAdd || onOptionEdit) &&
            (isAddingLoading || isEditingLoading ? (
              <LoadingSpinner loading={isAddingLoading || isEditingLoading} />
            ) : (
              <>
                <Button
                  data-test-id={`${dataTestId}-validate-add-button`}
                  onClick={() => {
                    if (isAdding && onOptionAdd) {
                      setIsAddingLoading(true);
                      onOptionAdd(newOption)?.finally(() => {
                        setIsAddingLoading(false);
                        setIsAdding(false);
                        setNewOption('');
                      });
                    } else if (optionToEdit && onOptionEdit) {
                      setIsEditingLoading(true);
                      onOptionEdit(newOption, optionToEdit)?.finally(() => {
                        setIsEditingLoading(false);
                        setOptionToEdit(undefined);
                        setNewOption('');
                      });
                    }
                  }}
                  className="size-6 flex items-center justify-center pointer-cursor hover:bg-neutral-200 dark:hover:bg-neutral-700 rounded-full px-1"
                >
                  <HiCheck
                    className="size-5 text-neutral-400 dark:text-white"
                    title="Validate"
                  />
                </Button>
                <Button
                  onClick={() => {
                    setIsAdding(false);
                    setOptionToEdit(undefined);
                    setNewOption('');
                  }}
                  className="size-6 flex items-center justify-center pointer-cursor hover:bg-neutral-200 dark:hover:bg-neutral-700 rounded-full px-1"
                >
                  <HiOutlineXMark
                    className="size-5 text-neutral-400 dark:text-white"
                    title="Cancel"
                  />
                </Button>
              </>
            ))}
        </div>
      )}
      {onOptionDelete && (
        <Modal
          title={intl.formatMessage({
            id: 'Confirm action',
          })}
          action={Action.REMOVE}
          open={!!optionToDelete}
          setShowModal={() => setOptionToDelete(null)}
          footer={
            <ModalFooter
              disabled={confirmDelete !== optionToDelete}
              action={Action.REMOVE}
              isLoading={isDeletingLoading}
              proceed={intl.formatMessage({
                id: 'Confirm',
              })}
              onProceed={() => {
                if (confirmDelete === optionToDelete) {
                  setIsDeletingLoading(true);
                  onOptionDelete(optionToDelete)?.finally(() => {
                    setIsDeletingLoading(false);
                    setOptionToDelete(undefined);
                    setConfirmDelete('');
                  });
                }
              }}
              onCancel={() => {
                setOptionToDelete(undefined);
                setConfirmDelete('');
              }}
            />
          }
        >
          <Subtitle
            value={intl.formatMessage(
              {
                id: 'Are you sure to remove option?',
              },
              {
                option: optionToDelete,
              },
            )}
            className="text-base pb-2"
          />
          <Input
            type="text"
            value={confirmDelete}
            renderIcon={({ className }) => (
              <HiOutlineTrash className={className} />
            )}
            id="remove-label"
            data-test-id={`remove-label-${dataTestId}`}
            onChangeValue={(input) => setConfirmDelete(input)}
            placeholder={optionToDelete || ''}
            required
          />
        </Modal>
      )}
    </>
  );
}
