import type { InitialSetup } from 'common/types';
import useStore from 'model/store';
import { FormattedMessage } from 'translations/Intl';

import { HiOutlineAcademicCap, HiOutlineXMark } from 'react-icons/hi2';

import Button from '../Form/Button';
import Tooltip from '../Tooltip';

interface HelpTooltipProps {
  children: React.JSX.Element;
  content: React.JSX.Element;
  stepName: keyof InitialSetup;
}

export default function HelpTooltip({
  content,
  stepName,
  children,
}: HelpTooltipProps) {
  const isSetup = useStore((state) => state.isSetup);
  const setIsSetup = useStore((state) => state.setIsSetup);
  const setInitialSetupSteps = useStore((state) => state.setInitialSetupSteps);
  const finished = useStore((state) => state.initialSetupSteps)[stepName];

  return !isSetup && !finished ? (
    <Tooltip
      tooltipOffset={{ crossAxis: 60, mainAxis: 40 }}
      staticPlacement
      content={content}
      className="shadow-lg border-primary-600 shadow-primary-600/50 p-4! font-bold rounded-xl"
      arrowClassName="border-primary-300 border-dashed border-2 w-0! h-10! rotate-0! dark:block!"
      arrowOffset={-40}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-2 items-center">
          <div>
            <HiOutlineAcademicCap className="size-5 text-primary-600" />
          </div>
          <div>{children}</div>
          <div className="justify-center">
            <HiOutlineXMark
              onClick={() => setInitialSetupSteps(stepName)}
              className="size-5 text-primary-600"
            />
          </div>
        </div>
        <div className="flex flex-row-reverse">
          <Button
            onClick={() => setIsSetup(true)}
            className="text-green-600 font-bold "
          >
            <FormattedMessage id="Got it" />
          </Button>
        </div>
      </div>
    </Tooltip>
  ) : (
    content
  );
}
