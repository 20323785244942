import { ClimateType, type MarginProps, Themes } from '@/common/types';
import { RAINBOW } from '@/constants';
import AnimatedPath from '@/generic/components/AnimatedPath';
import Axis from '@/generic/components/Chart/Axis';
import LoadingSpinner from '@/generic/components/LoadingSpinner';
import type { SensorAverageHistoryClimateQuery } from '@/graphql/types';
import useStore from '@/model/store';
import format from '@/utils/format';
import getColor from '@/utils/getColor';
import { lower, upper } from '@/utils/numberrange';
import { curveBasis } from '@visx/curve';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { Bar, LinePath } from '@visx/shape';
import { Threshold } from '@visx/threshold';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { bisector, extent } from 'd3-array';
import { Fragment, useState } from 'react';

const getPeriod = (d: ChartData) => d.Date;

const bisectDate = bisector<ChartData, Date>(getPeriod).left;

export type ChartData =
  SensorAverageHistoryClimateQuery['f_history_environment_daily'][number];

type TooltipData = { data: ChartData[]; date: string };

interface ResponsiveLineChartProps {
  margin?: MarginProps;
  data: ChartData[];
  loading: boolean;
  rooms: string[];
  type: ClimateType;
}

interface LineChartProps extends ResponsiveLineChartProps {
  height: number;
  width: number;
}

export const isContinousType = (
  type: ClimateType,
): type is
  | ClimateType.CO2EQ
  | ClimateType.CO2
  | ClimateType.TVOC
  | ClimateType.AUDIO => {
  return [
    ClimateType.CO2EQ,
    ClimateType.CO2,
    ClimateType.TVOC,
    ClimateType.AUDIO,
  ].includes(type);
};

function LineChart({
  height,
  width,
  margin = { top: 40, left: 60, right: 20, bottom: 30 },
  rooms,
  data,
  loading,
  type,
}: LineChartProps) {
  const [numTicks] = useState(4);
  const theme = useStore((state) => state.userSettings.theme);

  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  const xScale = scaleTime<number>({
    range: [0, xMax],
    domain: extent(data, getPeriod) as [Date, Date],
  });

  // Using lowerLimit and upperLimit values based on what can be inserted into the database
  const getContinousRating = (
    type:
      | ClimateType.CO2EQ
      | ClimateType.CO2
      | ClimateType.TVOC
      | ClimateType.AUDIO,
  ) => {
    switch (type) {
      case ClimateType.CO2EQ:
      case ClimateType.CO2:
        return {
          lowerLimit: 400,
          acceptable: lower(data[0]?.AcceptableValue!) ?? 1000,
          poor: lower(data[0]?.PoorValue!) ?? 2000,
          upperLimit: 3000,
        };
      case ClimateType.TVOC:
        return {
          lowerLimit: 0,
          acceptable: lower(data[0]?.AcceptableValue!) ?? 656,
          poor: lower(data[0]?.PoorValue!) ?? 2189,
          upperLimit: 3000,
        };
      case ClimateType.AUDIO:
        return {
          lowerLimit: 20,
          acceptable: lower(data[0]?.AcceptableValue!) ?? 40,
          poor: lower(data[0]?.PoorValue!) ?? 55,
          upperLimit: 70,
        };
    }
  };

  const getNonContinousRating = (
    type: ClimateType.TEMPERATURE | ClimateType.HUMIDITY,
  ) => {
    switch (type) {
      case ClimateType.TEMPERATURE:
        return {
          poorLower: 10,
          acceptableLower: lower(data[0]?.AcceptableValue!) ?? 21,
          goodLower: lower(data[0]?.GoodValue!) ?? 22,
          goodUpper: upper(data[0]?.GoodValue!) ?? 25,
          acceptableUpper: upper(data[0]?.AcceptableValue!) ?? 26,
          poorUpper: 40,
        };
      case ClimateType.HUMIDITY:
        return {
          poorLower: 0,
          acceptableLower: lower(data[0]?.GoodValue!) ?? 40,
          goodLower: lower(data[0]?.GoodValue!) ?? 40,
          goodUpper: upper(data[0]?.GoodValue!) ?? 60,
          acceptableUpper: upper(data[0]?.GoodValue!) ?? 60,
          poorUpper: 100,
        };
    }
  };

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: isContinousType(type)
      ? [
          getContinousRating(type).lowerLimit,
          getContinousRating(type).upperLimit,
        ]
      : [
          getNonContinousRating(type).poorLower,
          getNonContinousRating(type).poorUpper,
        ],
  });

  const colorScale = scaleOrdinal({
    domain: rooms,
    range: RAINBOW,
  });

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  return (
    <>
      <LoadingSpinner loading={loading} />
      <div className="relative">
        <svg width={width} height={height}>
          <Group top={margin.top} left={margin.left}>
            <GridRows
              numTicks={10}
              scale={yScale}
              width={xMax}
              height={yMax}
              strokeDasharray="1,3"
              stroke={getColor('NEUTRAL600')}
              strokeOpacity={0.6}
            />
            {isContinousType(type) ? (
              <>
                <Threshold
                  id={`good_${type}`}
                  data={data ?? []}
                  x={(x) => xScale(getPeriod(x)) ?? 0}
                  y0={() => yScale(getContinousRating(type).acceptable)}
                  y1={() => yScale(getContinousRating(type).lowerLimit)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  aboveAreaProps={{
                    fill: getColor('GREEN', '0.1'),
                  }}
                />
                <Threshold
                  id={`acceptable_${type}`}
                  data={data ?? []}
                  x={(x) => xScale(getPeriod(x)) ?? 0}
                  y0={() => yScale(getContinousRating(type).poor)}
                  y1={() => yScale(getContinousRating(type).acceptable)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  aboveAreaProps={{
                    fill: getColor('YELLOW', '0.1'),
                  }}
                />
                <Threshold
                  id={`poor_${type}`}
                  data={data ?? []}
                  x={(x) => xScale(getPeriod(x)) ?? 0}
                  y0={() => yScale(getContinousRating(type).upperLimit) ?? 0}
                  y1={() => yScale(getContinousRating(type).poor)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  aboveAreaProps={{
                    fill: getColor('RED', '0.1'),
                  }}
                />
              </>
            ) : (
              <>
                <Threshold
                  id={`poor_lower_${type}`}
                  data={data ?? []}
                  x={(x) => xScale(getPeriod(x)) ?? 0}
                  y0={() => yScale(getNonContinousRating(type).acceptableLower)}
                  y1={() => yScale(getNonContinousRating(type).poorLower)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  aboveAreaProps={{
                    fill: getColor('RED', '0.1'),
                  }}
                />
                <Threshold
                  id={`acceptable_lower_${type}`}
                  data={data ?? []}
                  x={(x) => xScale(getPeriod(x)) ?? 0}
                  y0={() => yScale(getNonContinousRating(type).goodLower)}
                  y1={() => yScale(getNonContinousRating(type).acceptableLower)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  aboveAreaProps={{
                    fill: getColor('YELLOW', '0.1'),
                  }}
                />
                <Threshold
                  id={`good_${type}`}
                  data={data ?? []}
                  x={(x) => xScale(getPeriod(x)) ?? 0}
                  y0={() => yScale(getNonContinousRating(type).goodUpper)}
                  y1={() => yScale(getNonContinousRating(type).goodLower)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  aboveAreaProps={{
                    fill: getColor('GREEN', '0.1'),
                  }}
                />
                <Threshold
                  id={`acceptable_upper_${type}`}
                  data={data ?? []}
                  x={(x) => xScale(getPeriod(x)) ?? 0}
                  y0={() => yScale(getNonContinousRating(type).acceptableUpper)}
                  y1={() => yScale(getNonContinousRating(type).goodUpper)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  aboveAreaProps={{
                    fill: getColor('YELLOW', '0.1'),
                  }}
                />
                <Threshold
                  id={`poor_upper_${type}`}
                  data={data ?? []}
                  x={(x) => xScale(getPeriod(x)) ?? 0}
                  y0={() => yScale(getNonContinousRating(type).poorUpper)}
                  y1={() => yScale(getNonContinousRating(type).acceptableUpper)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  aboveAreaProps={{
                    fill: getColor('RED', '0.1'),
                  }}
                />
              </>
            )}
            {rooms.map((r) => (
              <LinePath
                key={r}
                curve={curveBasis}
                data={data.filter((c) => c.Room === r)}
                x={(x) => xScale(getPeriod(x)) ?? 0}
                y={(y) => yScale(y.Value) ?? 0}
              >
                {({ path }) => (
                  <AnimatedPath
                    path={path}
                    data={data.filter((c) => c.Room === r)}
                    stroke={colorScale(r)}
                  />
                )}
              </LinePath>
            ))}
            {/* Just used for showing the tooltip */}
            <Bar
              width={xMax}
              height={yMax}
              fill="transparent"
              data-test-id="report-line-chart"
              onMouseMove={(event) => {
                const point = localPoint(event);
                if (point?.x) {
                  // 5px offset to actually show data for last entry
                  const offset = 5;
                  const x0 = xScale.invert(point.x - margin.left + offset);
                  const index = bisectDate(data, x0, 1);
                  const date = data[index - 1]?.Date;
                  if (date) {
                    showTooltip({
                      tooltipData: {
                        data: data.filter(
                          (d) =>
                            format(d.Date, 'eeeeee do LLL') ===
                            format(date, 'eeeeee do LLL'),
                        ),
                        date: format(date, 'eeeeee do LLL'),
                      },
                      tooltipTop: point?.y,
                      tooltipLeft: point.x,
                    });
                  }
                }
              }}
              onMouseOut={hideTooltip}
            />
            <Axis
              lowLevelChart
              orientation="left"
              tickFormat={(d) => `${d} ${data[0]?.Unit}`}
              numTicks={numTicks}
              scale={yScale}
            />
            <Axis
              lowLevelChart
              orientation="bottom"
              numTicks={width > 600 ? 5 : 2}
              tickFormat={(v: string) => format(new Date(v), 'eeeeee do LLL')}
              scale={xScale}
              top={yMax}
            />
          </Group>
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              background:
                theme.color === Themes.LIGHT
                  ? getColor('WHITE')
                  : getColor('NEUTRAL900'),
            }}
          >
            {tooltipData.data.map((d) => (
              <Fragment key={d.Id}>
                <div
                  data-test-id="climate-chart-tooltip"
                  className="dark:text-neutral-200"
                >
                  <strong
                    style={{
                      color: colorScale(d.Room),
                    }}
                  >
                    {d.Room}
                  </strong>{' '}
                  {d.Value.toFixed(2)} {d.Unit}
                </div>
              </Fragment>
            ))}
            <div className="dark:text-neutral-200">
              <small>{tooltipData.date}</small>
            </div>
          </TooltipWithBounds>
        )}
      </div>
    </>
  );
}

export default function ResponsiveLineChart(props: ResponsiveLineChartProps) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <LineChart {...props} height={height} width={width} />
      )}
    </ParentSize>
  );
}
