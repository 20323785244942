import Select from 'generic/components/Form/Select';
import { FormattedMessage, useIntl } from 'translations/Intl';

import Button from '@/generic/components/Form/Button';
import { HiChevronLeft, HiOutlineChevronRight } from 'react-icons/hi2';

interface PaginationProps {
  previousPage: () => void;
  canPreviousPage: boolean;
  nextPage: () => void;
  canNextPage: boolean;
  pageCount: number;
  pageIndex: number;
  total: number;
  pageSize: number;
  enablePageSize: boolean;
  setPageSize: (pageSize: number) => void;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
}

function Ellipsis() {
  return (
    <span className="relative inline-flex items-center px-4 py-2 border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-neutral-900 text-sm  text-neutral-700 dark:text-neutral-200">
      ...
    </span>
  );
}

export default function Pagination({
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
  total,
  gotoPage,
  pageSize,
  enablePageSize,
  setPageSize,
}: PaginationProps): React.JSX.Element {
  const intl = useIntl();
  const values = [10, 20, 30, 40, 50, 100, 500, 1000];
  const filteredValues = values.filter((item) => item <= total);
  // Just get the next bigger value from the list
  // (e.g. if it has 12 items it should have options for pagesize 10 and 20)
  const lastValue = values.filter((item) => item > total)[0];
  const options = lastValue ? [...filteredValues, lastValue] : filteredValues;

  const from = total === 0 ? 0 : pageIndex * pageSize + 1;
  const to =
    total > pageIndex * pageSize + pageSize
      ? pageIndex * pageSize + pageSize
      : total;

  return (
    <div className="sticky bottom-0 z-10 rounded-b-2xl bg-white print:hidden dark:bg-neutral-800 dark:border-neutral-700 px-4 py-3 flex items-center justify-between border-t border-neutral-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          type="button"
          className="relative inline-flex items-center px-4 py-2 border border-neutral-300 text-sm  rounded-md text-neutral-700 bg-white hover:bg-neutral-50"
        >
          <FormattedMessage id="Previous" />
        </button>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          type="button"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-neutral-300 text-sm  rounded-md text-neutral-700 bg-white hover:bg-neutral-50"
        >
          <FormattedMessage id="Next" />
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="flex items-center space-x-2">
          {enablePageSize && (
            <Select
              value={pageSize}
              isDeselectable={false}
              onChangeSelected={(size) => size && setPageSize(size)}
              options={options}
              renderValue={(t) =>
                `${intl.formatMessage({ id: 'Page Size' })}: ${t}`
              }
            />
          )}
          <div>
            <p className="text-sm text-neutral-700 dark:text-neutral-200">
              <FormattedMessage
                id="Showing results"
                values={{
                  from: from,
                  to: to,
                  total,
                }}
              />
            </p>
          </div>
        </div>
        <nav
          className="relative z-0 inline-flex rounded-md shadow-xs -space-x-px"
          aria-label="Pagination"
        >
          <Button
            onClick={previousPage}
            disabled={!canPreviousPage}
            className="cursor-pointer disabled:bg-neutral-100 dark:disabled:bg-neutral-700 disabled:cursor-default relative inline-flex items-center px-2 py-2 rounded-l-md border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-neutral-900 text-sm  text-neutral-500 hover:bg-neutral-50"
          >
            <span className="sr-only">
              <FormattedMessage id="Previous" />
            </span>
            <HiChevronLeft className="size-5" aria-hidden="true" />
          </Button>
          {Array.from(Array(pageCount)).map((_, i) =>
            [1, 2, 3, pageCount - 2, pageCount - 1, pageCount].includes(
              i + 1,
            ) ? (
              <Button
                key={`${Math.random()}-page`}
                aria-current="page"
                onClick={() => gotoPage(i)}
                className={`relative inline-flex items-center px-4 py-2 border text-sm 
                            ${
                              i === pageIndex
                                ? 'z-10 bg-primary-50 dark:bg-neutral-800 border-primary-500 text-primary-500'
                                : 'bg-white dark:border-neutral-700 dark:bg-neutral-900 border-neutral-300 text-neutral-500 hover:bg-neutral-50'
                            }`}
              >
                {i + 1}
              </Button>
            ) : (
              // Use the first index that has an ellipsis and add it for that one
              // this way it only gets added once
              i === 4 && <Ellipsis key={`${Math.random()}-page`} />
            ),
          )}
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="disabled:bg-neutral-100 dark:disabled:bg-neutral-700 disabled:cursor-default relative inline-flex items-center px-2 py-2 rounded-r-md border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-neutral-900 text-sm  text-neutral-500 hover:bg-neutral-50"
          >
            <span className="sr-only">
              <FormattedMessage id="Next" />
            </span>
            <HiOutlineChevronRight className="size-5" aria-hidden="true" />
          </Button>
        </nav>
      </div>
    </div>
  );
}
