import { FormattedMessage } from 'translations/Intl';

import type { Route } from '@/router/routes';
import { NavLink, Outlet } from 'react-router-dom';

import PrivateWrapper from '../PrivateWrapper';

export interface TabRoute extends Omit<Route, 'component'> {
  renderIcon: ({ className }: { className: string }) => React.JSX.Element;
  badge?: React.JSX.Element;
}

interface TabProps {
  tabs: TabRoute[];
}

export default function Tab({ tabs }: TabProps) {
  return (
    <>
      <div className="flex md:relative backdrop-blur-sm md:backdrop-blur-none bg-white/90 dark:bg-neutral-800/50 md:bg-transparent md:dark:bg-transparent z-30 md:z-0 fixed shadow-inner md:shadow-none bottom-0 left-0 right-0 w-full print:hidden border-t-2 dark:border-t-0 md:border-t-0">
        {tabs.map((route) => (
          <PrivateWrapper key={route.name} roleRequired={route.userRole}>
            <NavLink
              data-test-id={route.name}
              to={route.end ? '../.' : `../${route.path}`}
              end={route.end}
              className={({ isActive }) =>
                `flex print:hidden md:first:ml-2 md:last:mr-2 justify-evenly md:hover:border-b-2 w-full py-2.5 text-sm leading-5 font-semibold text-neutral-700 dark:text-neutral-50 md:text-primary-500 focus:outline-hidden border-transparent border-t-2 md:border-t-0 md:border-b-2 ${
                  isActive
                    ? 'md:border-b-2 border-b-0 border-primary-500! text-primary-500 '
                    : ''
                }`
              }
            >
              <div className="flex flex-col md:flex-row md:space-x-2 items-center text-center justify-center">
                <div className="flex justify-center">
                  {route.renderIcon({ className: 'md:size-5 size-6' })}
                </div>
                <div>
                  <FormattedMessage id={route.name} />
                </div>
                <div className="relative">{route.badge}</div>
              </div>
            </NavLink>
          </PrivateWrapper>
        ))}
      </div>
      <Outlet />
    </>
  );
}
