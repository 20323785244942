import {
  type Colors,
  type DrawingDeskFeatures,
  type JsonGeometry,
  type RoomFeatures,
  RoomTypes,
  type SnapFeatures,
  isNonNullable,
} from '@/common/types';
import { INTERSECTED_PARAM } from '@/constants';
import BaseLayer from '@/generic/layers/BaseLayer';
import SnapLayer from '@/generic/layers/SnapLayer';
import VectorLayer from '@/generic/layers/VectorLayer';
import AdminPolygonLayer from '@/generic/layers/adminView/AdminPolygonLayer';
import hiSun from '@/img/heroicons_sun.svg';
import rotateSVG from '@/img/rotate_right_black.svg';
import getColor from '@/utils/getColor';
import GeometryLayer, {
  isLineString,
  isPolygon,
} from 'generic/layers/GeometryLayer';
import type TypedFeature from 'generic/layers/TypedFeature';
import type { FloorMapFeaturesQuery } from 'graphql/types';
import type Feature from 'ol/Feature';
import type { Coordinate } from 'ol/coordinate';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { getCenter, getHeight, getWidth } from 'ol/extent';
import { MultiPoint } from 'ol/geom';
import type Geometry from 'ol/geom/Geometry';
import type LineString from 'ol/geom/LineString';
import type Point from 'ol/geom/Point';
import type Polygon from 'ol/geom/Polygon';
import { DragBox, Draw, Select } from 'ol/interaction';
import Modify from 'ol/interaction/Modify';
import OLSnap from 'ol/interaction/Snap';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { default as Circle, default as CircleStyle } from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { renderColor } from './components/AddDesksCard/DeskSensorActivation';
import DrawRoom from './interactions/drawRoom';
import MoveBeacon from './interactions/moveBeacon';
import MoveDesk from './interactions/moveDesk';
import RotateBeacon from './interactions/rotateBeacon';
import Snap from './interactions/snap';

export const deskStyle = (feat: DrawingDeskFeatures, resolution: number) => {
  const color = getColor(renderColor(feat.getProperties().Index ?? 1), '.5');
  if (!feat.getProperties().visible) {
    return new Style(undefined);
  }
  return new Style({
    geometry: feat.getGeometry(),
    image: new Circle({
      radius: feat.getProperties().Radius / resolution,
      fill: new Fill({
        color,
      }),
      stroke: new Stroke({
        color,
        width: 3,
      }),
    }),
  });
};

export const roomStyle = (
  feature: RoomFeatures,
  isSelected: boolean,
  isHovered = false,
  isIntersected = false,
  isPrivate = false,
) => {
  let color: keyof typeof Colors =
    feature.getProperties().RoomType.Name === RoomTypes.DESKS
      ? 'PRIMARY400'
      : 'GREEN';

  if (isPrivate) {
    color = 'NEUTRAL600';
  }

  if (isIntersected) {
    return new Style({
      stroke: new Stroke({
        color: getColor(color, '.5'),
        lineDash: [7, 10],
        width: isHovered ? 5 : 3,
      }),
      fill: new Fill({
        color: getColor(color, '.5'),
      }),
    });
  }

  if (isSelected) {
    return [
      // Show vertices (single polygon point)
      new Style({
        image: new Circle({
          radius: 5,
          fill: new Fill({
            color: getColor(color, '1'),
          }),
        }),
        geometry: (feature) => {
          // Return the coordinates of the first ring of the polygon
          const coordinates = (feature as RoomFeatures)
            .getGeometry()
            ?.getCoordinates()[0];

          return new MultiPoint(coordinates ?? [0, 0]);
        },
      }),
      new Style({
        stroke: new Stroke({
          color: 'white',
          lineDash: [7, 10],
          width: 5,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: getColor(color, '1'),
          lineDash: [7, 10],
          width: 3,
        }),
        fill: new Fill({
          color: getColor(color, '.5'),
        }),
      }),
    ];
  }

  return new Style({
    stroke: new Stroke({
      color: getColor(color, '.5'),
      width: isHovered ? 5 : 2,
    }),
    fill: new Fill({
      color: getColor(color, '.2'),
    }),
  });
};

export const baseLayer = new BaseLayer();
export const roomLayer = new GeometryLayer<
  FloorMapFeaturesQuery['Rooms'][number],
  Polygon
>({
  name: 'adminRoom',
  style: (feat, _, hoveredFeature, __, selectedFeatureId) => {
    const isPrivate = feat.getProperties().IsPrivate;
    const isHovered =
      feat.getProperties().Id === hoveredFeature?.getProperties().Id;
    const isSelected = feat.getProperties().Id === selectedFeatureId;
    const isIntersected = feat.get(INTERSECTED_PARAM);

    const style = roomStyle(
      feat,
      isSelected,
      isHovered,
      isIntersected,
      isPrivate,
    );

    return Array.isArray(style) ? style : [style];
  },
});

export const desksLayerName = 'admin-desk';
export const desksLayer = new GeometryLayer<
  FloorMapFeaturesQuery['Desks'][number],
  Point
>({
  style: (feat, resolution, hoveredFeature, showLabel, selectedFeatureId) =>
    feat.getProperties().Sensor?.MqttBeacon.Id === (selectedFeatureId ?? 0)
      ? // Hide desks when they are being moved
        undefined
      : [
          new Style({
            image: new Circle({
              radius: feat.getProperties().Radius / resolution,
              fill: new Fill({
                color: getColor(
                  'PRIMARY400',
                  feat === hoveredFeature ? '.9' : '.5',
                ),
              }),
              stroke: new Stroke({
                color: getColor('PRIMARY400', '.5'),
                width: 3,
              }),
            }),
            text: showLabel
              ? new Text({
                  text: feat.getProperties().Sensor.Index.toString(),
                  font: 'bold 15px Calibri,sans-serif',
                })
              : undefined,
          }),
        ],
  name: desksLayerName,
});

export const beaconStyle = (
  feat:
    | TypedFeature<FloorMapFeaturesQuery['smartModules'][number], Geometry>
    | TypedFeature<FloorMapFeaturesQuery['MqttBeacons'][number], Geometry>,
  isDesk: boolean,
  showLabel?: boolean,
  hovered?:
    | TypedFeature<FloorMapFeaturesQuery['smartModules'][number], Geometry>
    | TypedFeature<FloorMapFeaturesQuery['MqttBeacons'][number], Geometry>,
  selectedId?: number | string,
  showClimateSensors?: boolean,
): Style[] => {
  const isHovered = hovered?.getProperties().Name === feat.getProperties().Name;
  const isFeatSelected = feat.getProperties().Id === selectedId;
  const defaultColor = isDesk ? 'YELLOW' : 'GREEN';
  const color = isFeatSelected ? 'PRIMARY400' : defaultColor;
  const rotation = feat.getProperties().Rotation;
  const hasClimateSensors = feat
    .getProperties()
    .Sensors.some((s) => s.SensorType.IsClimateSensor);

  return [
    new Style({
      image: new Circle({
        radius: 7,
        fill: new Fill({
          color: getColor(color, isFeatSelected || isHovered ? '.9' : '.5'),
        }),
        stroke: isFeatSelected
          ? new Stroke({
              color: 'white',
              width: 1,
            })
          : new Stroke({
              color: getColor(color, isHovered ? '.9' : '.5'),
              width: 3,
            }),
      }),
    }),
    showClimateSensors && hasClimateSensors
      ? new Style({
          image: new Icon({
            src: hiSun,
            scale: 1,
            anchor: [0.5, 12.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
          }),
        })
      : undefined,
    // Do not show the label when the feature is selected as it interferes with moving the feature
    showLabel && !isFeatSelected
      ? new Style({
          text: new Text({
            text: feat.getProperties().Name,
            font: 'bold 15px Calibri,sans-serif',
            backgroundFill: new Fill({
              color: getColor(color, '.5'),
            }),
            padding: [2, 2, 2, 2],
            offsetY: rotation > 0 ? -20 : 20,
          }),
        })
      : undefined,
  ].filter(isNonNullable);
};

export const beaconLayerName = 'admin-beacon';
export const beaconsLayer = new GeometryLayer<
  FloorMapFeaturesQuery['MqttBeacons'][number],
  Point
>({
  name: beaconLayerName,
  style: (
    feat,
    _,
    hoveredFeature,
    showLabel,
    selectedFeatureId,
    showClimateSensors,
  ) =>
    beaconStyle(
      feat,
      true,
      showLabel,
      hoveredFeature,
      selectedFeatureId,
      showClimateSensors,
    ),
});

export const beaconModuleLayerName = 'admin-beacon-module';
export const beaconsModuleLayer = new GeometryLayer<
  FloorMapFeaturesQuery['smartModules'][number],
  Point
>({
  name: beaconModuleLayerName,
  style: (
    feat,
    _,
    hoveredFeature,
    showLabel,
    selectedFeatureId,
    showClimateSensors,
  ) =>
    beaconStyle(
      feat,
      false,
      showLabel,
      hoveredFeature,
      selectedFeatureId,
      showClimateSensors,
    ),
});

export const snapLineForBeaconLayerName = 'snapping-lines-for-beacon';
export const snapLineForBeaconSource = new VectorSource<
  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  Feature<Geometry> | SnapFeatures
>({
  wrapX: false,
});
export const snapLineForBeaconLayer = new SnapLayer({
  name: snapLineForBeaconLayerName,
  source: snapLineForBeaconSource,
});

export const snapLineForBeaconInteraction = new Snap({
  source: snapLineForBeaconSource,
  snapLayer: snapLineForBeaconLayer,
});

// TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
export const drawingRoomSource = new VectorSource<Feature<Geometry | Polygon>>({
  wrapX: false,
});
export const modifyRoomSource = new VectorSource<Feature<Geometry | Polygon>>({
  wrapX: false,
});

// Snap source/interaction to draw room, with right-angled lines (hold Ctrl key)
export const snapLineForRoomSource = new VectorSource<
  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  Feature<Geometry | LineString>
>({
  wrapX: false,
});
export const snapLineForRoomLayer = new VectorLayer({
  name: 'snapping-lines-for-room',
  olLayer: new OLVectorLayer({
    source: snapLineForRoomSource,
    style: () => new Style(),
  }),
});

export const snapLineForRoomInteraction = new OLSnap({
  source: snapLineForRoomSource,
});
export const drawRoom = new DrawRoom({
  source: drawingRoomSource,
  snapSource: snapLineForRoomSource,
});
export const drawingRoomLayerName = 'drawing-room';
export const drawingRoomLayer = new VectorLayer({
  name: drawingRoomLayerName,
  olLayer: new OLVectorLayer({
    source: drawingRoomSource,
    style: () => [
      new Style({
        stroke: new Stroke({
          color: getColor('YELLOW', '.5'),
          width: 3,
        }),
        fill: new Fill({
          color: getColor('YELLOW', '.5'),
        }),
      }),
    ],
  }),
});

// TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
export const drawingBeaconSource = new VectorSource<Feature<Geometry | Point>>({
  wrapX: false,
});
export const drawingDesksSource = new VectorSource<Feature<Point>>({
  wrapX: false,
});
export const drawingBeaconLayerName = 'drawing-beacon';
export const drawingBeaconLayer = new GeometryLayer<any, Geometry | Point>({
  name: drawingBeaconLayerName,
  olLayer: new OLVectorLayer({
    source: drawingBeaconSource,
  }),
});

export const drawingDeskLayerName = 'drawing-desk';
export const drawingDesksLayer = new VectorLayer({
  name: drawingDeskLayerName,
  olLayer: new OLVectorLayer({
    source: drawingDesksSource,
    style: (feat, resolution) =>
      deskStyle(feat as DrawingDeskFeatures, resolution),
  }),
});

export const modifyRoomLayer = new AdminPolygonLayer({
  name: 'modifyRoom',
  source: modifyRoomSource,
  styleFunction: (
    feat: RoomFeatures,
    _resolution: number,
    _color: keyof typeof Colors,
    _selected: RoomFeatures,
    _: boolean,
    highlighted: RoomFeatures | null,
  ) => {
    const isPrivate = feat.getProperties().IsPrivate;
    const isIntersected = feat.get(INTERSECTED_PARAM);

    return roomStyle(
      feat,
      true,
      feat === highlighted,
      isIntersected,
      isPrivate,
    );
  },
});

export const roomOutlineSource = new VectorSource<Feature<Geometry>>({
  wrapX: false,
});

export const roomOutlineLayer = new VectorLayer({
  name: 'room-outline',
  olLayer: new OLVectorLayer({
    source: roomOutlineSource,
    style: (feature) => {
      const styles = [
        new Style({
          geometry: (feature) => {
            const modifyGeometry = feature.get('modifyGeometry');
            return modifyGeometry
              ? modifyGeometry.geometry
              : feature.getGeometry();
          },
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: getColor('PRIMARY400'),
            width: 3,
            lineDash: [7, 10],
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: '#ffcc33',
            }),
          }),
        }),
      ];
      const modifyGeometry = feature.get('modifyGeometry');
      const geometry = modifyGeometry
        ? modifyGeometry.geometry
        : feature.getGeometry();

      const { minRadius, sqDistances, coordinates } = calculateCenter(geometry);

      if (coordinates) {
        const rsq = minRadius * minRadius;
        const points = coordinates.filter(
          (_, index) => sqDistances![index]! > rsq,
        );
        // Add style at vertixes of room outline
        styles.push(
          new Style({
            geometry: new MultiPoint(points),
            image: new CircleStyle({
              radius: 4,
              fill: new Fill({
                color: getColor('PRIMARY400'),
              }),
            }),
          }),
        );
      }
      return styles;
    },
  }),
});

export const outlineLayer = new GeometryLayer<
  { Geometry: JsonGeometry },
  Geometry | Polygon
>({
  name: 'outline',
  style: (feature) => {
    const geometry = feature.getGeometry()?.clone() as Geometry;

    return [
      new Style({
        geometry,
        stroke: new Stroke({
          color: getColor('NEUTRAL600'),
          width: 2,
        }),
      }),
    ];
  },
});

export const deskOutlineSource = new VectorSource<Feature<Polygon>>({
  wrapX: false,
});

export const deskOutlineLayer = new VectorLayer({
  name: 'desk-outline',
  olLayer: new OLVectorLayer({
    source: deskOutlineSource,
    style: (feature) => {
      const geometry = feature.getGeometry() as Geometry;
      return [
        new Style({
          geometry,
          stroke: new Stroke({
            color: 'white',
            width: 5,
            lineDash: [7, 10],
          }),
        }),
        new Style({
          geometry,
          stroke: new Stroke({
            color: getColor('PRIMARY400'),
            width: 3,
            lineDash: [7, 10],
          }),
        }),
      ];
    },
  }),
});

export const rotateAnchorLayerName = 'rotate-anchor';
export const rotateAnchorSource = new VectorSource<Feature<Point>>({
  wrapX: false,
});
export const rotateAnchorLayer = new VectorLayer({
  name: rotateAnchorLayerName,
  olLayer: new OLVectorLayer({
    source: rotateAnchorSource,
    style: () => [
      new Style({
        image: new Icon({
          src: rotateSVG,
        }),
      }),
      // Empty circle to allow rotation interaction
      // when clicks on the center of the rotate icon.
      new Style({
        image: new Circle({
          radius: 9,
          fill: new Fill({
            // transparent color
            color: 'rgba(0,0,0,.01)',
          }),
        }),
      }),
    ],
  }),
});

const extraLimitSource = new VectorSource<Feature<Polygon>>({
  wrapX: false,
});
export const extraLimitLayer = new VectorLayer({
  name: 'extraLimitSource',
  olLayer: new OLVectorLayer({
    source: extraLimitSource,
    style: (feat) => [
      new Style({
        stroke: new Stroke({
          color: getColor(renderColor(feat.get('index')), '.3'),
          lineDash: [7, 10],
          width: 3,
        }),
        fill: new Fill({
          color: getColor(renderColor(feat.get('index')), '.1'),
        }),
      }),
    ],
  }),
});

export const deskMove = new MoveDesk({
  layer: drawingDesksLayer,
  beaconLayers: [beaconsModuleLayer, beaconsLayer],
  drawingBeaconLayer,
  extraLimitSource,
});

export const beaconMoveInteractions = [
  new MoveBeacon({
    layer: drawingBeaconLayer,
    source: drawingBeaconSource,
    desksSource: drawingDesksSource,
    outlineSource: deskOutlineSource,
    rotateAnchorSource,
  }),
  new MoveBeacon({
    layer: beaconsModuleLayer,
    source: beaconsModuleLayer.olLayer.getSource() as VectorSource<
      Feature<Geometry | Point>
    >,
    desksSource: drawingDesksSource,
    outlineSource: deskOutlineSource,
    rotateAnchorSource,
  }),
  new MoveBeacon({
    layer: beaconsLayer,
    source: beaconsLayer.olLayer.getSource() as VectorSource<
      Feature<Geometry | Point>
    >,
    desksSource: drawingDesksSource,
    outlineSource: deskOutlineSource,
    rotateAnchorSource,
  }),
];

export const rotateBeacon = new RotateBeacon({
  source: rotateAnchorSource,
  drawingDesksSource,
  beaconsLayer,
  drawingBeaconSource,
});

export function calculateCenter(geometry: Geometry) {
  let center: Coordinate;
  let coordinates: Coordinate[] | undefined;
  let minRadius: number;
  let sqDistances: number[] | undefined;

  if (isPolygon(geometry)) {
    let x = 0;
    let y = 0;
    let i = 0;
    coordinates = geometry.getCoordinates()[0]!.slice(1);
    for (const coordinate of coordinates) {
      x += coordinate[0]!;
      y += coordinate[1]!;
      i++;
    }
    center = [x / i, y / i];
  } else if (isLineString(geometry)) {
    center = geometry.getCoordinateAt(0.5);
    coordinates = geometry.getCoordinates();
  } else {
    center = getCenter(geometry.getExtent());
  }
  if (coordinates) {
    sqDistances = coordinates.map((coordinate) => {
      const dx = coordinate[0]! - center[0]!;
      const dy = coordinate[1]! - center[1]!;
      return dx * dx + dy * dy;
    });
    minRadius = Math.sqrt(Math.max.apply(Math, sqDistances)) / 3;
  } else {
    minRadius =
      Math.max(
        getWidth(geometry.getExtent()),
        getHeight(geometry.getExtent()),
      ) / 3;
  }
  return {
    center,
    coordinates,
    minRadius,
    sqDistances,
  };
}

export const ROOM_OUTLINE_FEATURE_NAME = 'room-outline-feature';

export const roomModify = new Modify({
  source: modifyRoomSource,
});

export const dragBox = new DragBox({
  condition: platformModifierKeyOnly,
  className: 'bg-primary-500/20',
});

export const select = new Select({
  style: () => {
    return [
      new Style({
        fill: new Fill({
          color: getColor('PRIMARY400', '.5'),
        }),
        stroke: new Stroke({
          color: getColor('PRIMARY400', '.5'),
          width: 3,
          lineDash: [7, 10],
        }),
      }),
    ];
  },
});

export const drawMeasureDistanceSource = new VectorSource<
  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  Feature<Geometry | LineString>
>();
export const drawMeasureDistanceLine = new Draw({
  type: 'LineString',
  maxPoints: 2,
  source: drawMeasureDistanceSource,
});
export const drawMeasureDistanceLayer = new VectorLayer({
  name: 'measure-distance-layer',
  olLayer: new OLVectorLayer({
    source: drawMeasureDistanceSource,
    style: () => [
      new Style({
        stroke: new Stroke({
          color: getColor('YELLOW', '.7'),
          width: 5,
        }),
      }),
    ],
  }),
});
