import { FormattedMessage } from 'translations/Intl';

import {
  HiExclamationCircle,
  HiOutlineCheck,
  HiOutlineXMark,
} from 'react-icons/hi2';
import { Link } from 'react-router-dom';

export default function CountInfo({
  absolute,
  relative,
  color,
  url,
  ...rest
}: {
  absolute: number;
  relative: number;
  color: 'green' | 'yellow' | 'red' | 'neutral';
  url?: string;
}) {
  const getBackgroundColor = () => {
    if (color === 'green') return 'bg-green-600';
    if (color === 'yellow') return 'bg-yellow-500';
    if (color === 'neutral') return 'bg-neutral-500';
    return 'bg-red-600';
  };

  const getIcon = () => {
    if (color === 'green')
      return <HiOutlineCheck className="size-5 text-green-600" />;
    if (color === 'yellow')
      return <HiOutlineXMark className="size-5 text-yellow-500" />;
    if (color === 'neutral')
      return <HiExclamationCircle className="size-5 text-neutral-500" />;
    return <HiOutlineXMark className="size-5 text-red-600" />;
  };

  const getMessage = () => {
    if (color === 'green') return 'free';
    if (color === 'yellow') return 'warm';
    if (color === 'neutral') return 'offline';
    return 'hot';
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center space-x-2">
        <div className={`${getBackgroundColor()} p-2 rounded-sm`}>
          <div className="rounded-full bg-white p-1">{getIcon()}</div>
        </div>
        <div className="flex flex-col">
          <div className="font-bold">
            {url ? (
              <Link
                className="hover:text-primary-500"
                to={url.replaceAll('%20', '+')}
              >
                <FormattedMessage id={getMessage()} />
              </Link>
            ) : (
              <FormattedMessage id={getMessage()} />
            )}
          </div>
          <div className="text-neutral-500">{`${relative.toFixed(2)} %`}</div>
        </div>
      </div>
      <div className="text-xl font-bold" {...rest}>
        {absolute}
      </div>
    </div>
  );
}
