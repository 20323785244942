import { FormattedMessage } from 'translations/Intl';
import Button from './Button';

interface CloseButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function CloseButton({ onClick }: CloseButtonProps) {
  return (
    <Button
      onClick={onClick}
      className="mt-3 w-full inline-flex justify-center rounded-md border border-neutral-300 dark:border-neutral-700 shadow-xs px-4 py-2 items-center bg-white dark:bg-neutral-700 text-neutral-700 dark:text-neutral-200 hover:bg-neutral-50 dark:hover:bg-neutral-800 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto text-base md:text-sm"
    >
      <FormattedMessage id="Close" />
    </Button>
  );
}
