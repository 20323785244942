import useStore from '@/model/store';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import { ClimateType, type SensorValue } from 'common/types';
import Subtitle from 'generic/components/Subtitle';
import Tooltip from 'generic/components/Tooltip';
import {
  type ClimateSensorLimits_Insert_Input,
  useClimateSensorLimitsQuery,
} from 'graphql/types';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { lower, serializeRange, upper } from 'utils/numberrange';
import ClimateSlider from './components/ClimateSlider';

interface LimitSelectProps {
  setClimateObjects: (objects: ClimateSensorLimits_Insert_Input[]) => void;
}

export const defaultClimateValues: { [key in ClimateType]: SensorValue } = {
  [ClimateType.TEMPERATURE]: {
    good: {
      start: { value: 22, inclusive: true },
      end: { value: 25, inclusive: false },
    },
    acceptable: {
      start: { value: 21, inclusive: true },
      end: { value: 26, inclusive: false },
    },
    poor: {
      start: { value: 26, inclusive: true },
    },
  },
  [ClimateType.HUMIDITY]: {
    good: {
      start: { value: 30, inclusive: true },
      end: { value: 60, inclusive: false },
    },
    acceptable: {},
    poor: {
      start: { value: 60, inclusive: true },
    },
  },
  [ClimateType.TVOC]: {
    good: {
      end: { value: 656, inclusive: false },
    },
    acceptable: {
      start: { value: 656, inclusive: true },
      end: { value: 2189, inclusive: false },
    },
    poor: {
      start: { value: 2189, inclusive: true },
    },
  },
  [ClimateType.CO2EQ]: {
    good: {
      end: { value: 1000, inclusive: false },
    },
    acceptable: {
      start: { value: 1000, inclusive: true },
      end: { value: 2000, inclusive: false },
    },
    poor: {
      start: { value: 2000, inclusive: true },
    },
  },
  [ClimateType.CO2]: {
    good: {
      end: { value: 1000, inclusive: false },
    },
    acceptable: {
      start: { value: 1000, inclusive: true },
      end: { value: 2000, inclusive: false },
    },
    poor: {
      start: { value: 2000, inclusive: true },
    },
  },
  [ClimateType.AUDIO]: {
    good: {
      end: { value: 40, inclusive: false },
    },
    acceptable: {
      start: { value: 40, inclusive: true },
      end: { value: 55, inclusive: false },
    },
    poor: {
      start: { value: 55, inclusive: true },
    },
  },
};

export default function LimitSelect({ setClimateObjects }: LimitSelectProps) {
  const intl = useIntl();
  const userRoles = useStore((state) => state.user?.roles);
  const [temperature, setTemperature] = useState(
    defaultClimateValues.clc_temperature,
  );
  const [humidity, setHumidity] = useState(defaultClimateValues.clc_humidity);
  const [tvoc, setTvoc] = useState(defaultClimateValues.clc_tvoc);
  const [co2, setCo2] = useState(defaultClimateValues.clc_co2eq);
  const [audio, setAudio] = useState(defaultClimateValues.clc_audio);

  const [{ data }] = useClimateSensorLimitsQuery();

  const getLimit = useCallback(
    (type: ClimateType) =>
      data?.ClimateSensorLimits.find((s) => s.SensorType.Name === type),
    [data?.ClimateSensorLimits],
  );

  const getUnitForSensorType = useCallback(
    (type: ClimateType) => getLimit(type)?.SensorType.Unit,
    [getLimit],
  );

  useEffect(() => {
    if (data?.ClimateSensorLimits[0]) {
      const tvocSensor = getLimit(ClimateType.TVOC)!;
      const temperatureSensor = getLimit(ClimateType.TEMPERATURE)!;
      const co2Sensor = getLimit(ClimateType.CO2EQ)!;
      const audioSensor = getLimit(ClimateType.AUDIO)!;
      const humiditySensor = getLimit(ClimateType.HUMIDITY)!;

      setTemperature({
        good: {
          start: {
            value: lower(temperatureSensor.GoodValue),
            inclusive: true,
          },
          end: {
            value: upper(temperatureSensor.GoodValue),
            inclusive: false,
          },
        },
        acceptable: {
          start: {
            value: lower(temperatureSensor.AcceptableValue),
            inclusive: true,
          },
          end: {
            value: upper(temperatureSensor.AcceptableValue),
            inclusive: false,
          },
        },
        poor: {
          start: {
            value: lower(temperatureSensor.PoorValue),
            inclusive: true,
          },
        },
      });

      setTvoc({
        good: {
          end: { value: upper(tvocSensor.GoodValue), inclusive: false },
        },
        acceptable: {
          start: {
            value: lower(tvocSensor.AcceptableValue),
            inclusive: true,
          },
          end: {
            value: upper(tvocSensor.AcceptableValue),
            inclusive: false,
          },
        },
        poor: {
          start: { value: lower(tvocSensor.PoorValue), inclusive: true },
        },
      });

      setCo2({
        good: {
          end: { value: upper(co2Sensor.GoodValue), inclusive: false },
        },
        acceptable: {
          start: {
            value: lower(co2Sensor.AcceptableValue),
            inclusive: true,
          },
          end: {
            value: upper(co2Sensor.AcceptableValue),
            inclusive: false,
          },
        },
        poor: {
          start: { value: lower(co2Sensor.PoorValue), inclusive: true },
        },
      });

      setAudio({
        good: {
          end: { value: upper(audioSensor.GoodValue), inclusive: false },
        },
        acceptable: {
          start: {
            value: lower(audioSensor.AcceptableValue),
            inclusive: true,
          },
          end: {
            value: upper(audioSensor.AcceptableValue),
            inclusive: false,
          },
        },
        poor: {
          start: { value: lower(audioSensor.PoorValue), inclusive: true },
        },
      });

      setHumidity({
        good: {
          start: {
            value: lower(humiditySensor.GoodValue),
            inclusive: true,
          },
          end: {
            value: upper(humiditySensor.GoodValue),
            inclusive: false,
          },
        },
        acceptable: {},
        poor: {
          start: {
            value: lower(humiditySensor.PoorValue),
            inclusive: true,
          },
        },
      });
    }
  }, [data?.ClimateSensorLimits[0], getLimit]);

  useEffect(
    () =>
      setClimateObjects([
        {
          AcceptableValue: serializeRange(tvoc.acceptable),
          GoodValue: serializeRange(tvoc.good),
          PoorValue: serializeRange(tvoc.poor),
          SensorTypeId: getLimit(ClimateType.TVOC)?.SensorType.Id,
        },
        {
          AcceptableValue: serializeRange(temperature.acceptable),
          GoodValue: serializeRange(temperature.good),
          PoorValue: serializeRange(temperature.poor),
          SensorTypeId: getLimit(ClimateType.TEMPERATURE)?.SensorType.Id,
        },
        {
          AcceptableValue: serializeRange(co2.acceptable),
          GoodValue: serializeRange(co2.good),
          PoorValue: serializeRange(co2.poor),
          SensorTypeId: getLimit(ClimateType.CO2EQ)?.SensorType.Id,
        },
        {
          AcceptableValue: serializeRange(humidity.acceptable),
          GoodValue: serializeRange(humidity.good),
          PoorValue: serializeRange(humidity.poor),
          SensorTypeId: getLimit(ClimateType.HUMIDITY)?.SensorType.Id,
        },
        {
          AcceptableValue: serializeRange(audio.acceptable),
          GoodValue: serializeRange(audio.good),
          PoorValue: serializeRange(audio.poor),
          SensorTypeId: getLimit(ClimateType.AUDIO)?.SensorType.Id,
        },
      ]),
    [
      audio.acceptable,
      audio.good,
      audio.poor,
      co2.acceptable,
      co2.good,
      co2.poor,
      getLimit,
      humidity.acceptable,
      humidity.good,
      humidity.poor,
      setClimateObjects,
      temperature.acceptable,
      temperature.good,
      temperature.poor,
      tvoc.acceptable,
      tvoc.good,
      tvoc.poor,
    ],
  );

  return (
    <>
      <div className="flex space-x-2 items-center">
        <Subtitle value={intl.formatMessage({ id: 'Climate limits' })} />
        <Tooltip>
          <FormattedMessage id="climate-limit-description" />
        </Tooltip>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-8 md:gap-y-12 px-8 md:px-0">
        <ClimateSlider
          unit={getUnitForSensorType(ClimateType.TVOC)!}
          domain={[0, 3000]}
          title={intl.formatMessage({ id: ClimateType.TVOC })}
          range={tvoc}
          handleChange={([_, acceptableStart, acceptableEnd]) =>
            setTvoc({
              good: {
                end: { value: acceptableStart!, inclusive: false },
              },
              acceptable: {
                start: { value: acceptableStart!, inclusive: true },
                end: { value: acceptableEnd!, inclusive: false },
              },
              poor: {
                start: { value: acceptableEnd!, inclusive: true },
              },
            })
          }
        />
        <ClimateSlider
          unit={getUnitForSensorType(ClimateType.AUDIO)!}
          domain={[20, 90]}
          title={intl.formatMessage({ id: ClimateType.AUDIO })}
          range={audio}
          handleChange={([_, acceptableStart, acceptableEnd]) =>
            setAudio({
              good: {
                end: { value: acceptableStart!, inclusive: false },
              },
              acceptable: {
                start: { value: acceptableStart!, inclusive: true },
                end: { value: acceptableEnd!, inclusive: false },
              },
              poor: {
                start: { value: acceptableEnd!, inclusive: true },
              },
            })
          }
        />
        <ClimateSlider
          unit={getUnitForSensorType(ClimateType.CO2EQ)!}
          domain={[400, 3000]}
          title={intl.formatMessage({ id: ClimateType.CO2EQ })}
          range={co2}
          handleChange={([_, acceptableStart, acceptableEnd]) =>
            setCo2({
              good: {
                end: { value: acceptableStart!, inclusive: false },
              },
              acceptable: {
                start: { value: acceptableStart!, inclusive: true },
                end: { value: acceptableEnd!, inclusive: false },
              },
              poor: {
                start: { value: acceptableEnd!, inclusive: true },
              },
            })
          }
        />
        {/* TODO: Add when humidity is stable */}
        {userRoles?.includes(HasuraPermissions.READ_ALL) && (
          <ClimateSlider
            unit={getUnitForSensorType(ClimateType.HUMIDITY)!}
            domain={[0, 100]}
            title={intl.formatMessage({ id: ClimateType.HUMIDITY })}
            range={humidity}
            handleChange={([goodStart, poorStart]) =>
              setHumidity({
                good: {
                  start: { value: goodStart!, inclusive: true },
                  end: { value: poorStart!, inclusive: false },
                },
                acceptable: {},
                poor: {
                  start: { value: poorStart!, inclusive: true },
                },
              })
            }
          />
        )}
        <ClimateSlider
          unit={getUnitForSensorType(ClimateType.TEMPERATURE)!}
          domain={[15, 35]}
          title={intl.formatMessage({ id: ClimateType.TEMPERATURE })}
          range={temperature}
          handleChange={([
            acceptableStart,
            goodStart,
            goodEnd,
            acceptableEnd,
          ]) => {
            return setTemperature({
              good: {
                start: { value: goodStart!, inclusive: true },
                end: { value: goodEnd!, inclusive: false },
              },
              acceptable: {
                start: { value: acceptableStart!, inclusive: true },
                end: { value: acceptableEnd!, inclusive: false },
              },
              poor: {
                start: { value: acceptableEnd!, inclusive: true },
              },
            });
          }}
        />
      </div>
    </>
  );
}
