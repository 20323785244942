import type { LiveRoomOccupancyQuery } from 'graphql/types';

export function colorForOccupancy(occupancy: number): string {
  if (occupancy > 0) return 'bg-red-600';
  return 'bg-green-600';
}

interface SingleRoomOccupancyProps {
  room: LiveRoomOccupancyQuery['f_live_rooms_occupancy'][number];
}

export default function SingleRoomOccupancy({
  room,
}: SingleRoomOccupancyProps) {
  const offline = room.IsOffline;
  return (
    <div className="flex rounded-sm shadow-sm h-16 space-x-2 bg-white dark:bg-neutral-900">
      <div
        className={`flex w-1/4 ${
          offline
            ? 'bg-neutral-300'
            : colorForOccupancy(room.RoomOccupancyPercentage)
        } uppercase text-neutral-100 items-center`}
      >
        <p className="text-center w-full">{room.Name.substring(0, 2)}</p>
      </div>
      <div className="flex w-3/4 items-center">
        <div className="flex flex-col">
          <div className="font-bold dark:text-neutral-50">{room.Name}</div>
          <div className="text-neutral-500 dark:text-neutral-100">
            {!room.IsPresenceSensor && !offline && (
              <>
                {' '}
                <span>
                  ({room.RoomOccupancy > 0 ? room.RoomOccupancy : 0}
                  {room.Capacity > 0 ? `/${room.Capacity}` : ''})
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
